import React from "react";
import styles from "./Fund.module.css";

const Fund = () => {
  return (
    <div className={styles.midContainer}>
      <div className={styles.LeftContent}>
        <iframe
          width="300"
          height="442"
          src="https://www.youtube.com/embed/FLVWFhhOGw0"
          title="Fabits Introduction"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerPolicy="strict-origin-when-cross-origin"
          allowFullScreen
        ></iframe>
      </div>
      <div className={styles.rcontent}>
        <div className={styles.heading}>
          <span>Why choose Gold ETFs over physical gold? 🏆 </span>
        </div>

        <div className={styles.para}>
          <span>
            With a Gold ETF, you get the benefits of owning gold without the
            hassle of storage, security, or additional costs. Plus, it’s easy to
            buy and sell anytime! 📈✨ Start with as little as 13 rupees on the
            Fabits platform.
          </span>
        </div>
      </div>
    </div>
  );
};

export default Fund;
