import React from "react";
import "./InvestorAwareness.css";
import { Helmet } from "react-helmet";
import Table from "../components/common/Table/Table";
import {
  Account_closure_policy,
  Fabits_CDSL_Do_and_Donts_for_investor_16_07_2024,
  Fabits_Guidance_note_for_investors_16_07_2024,
  Fabits_NSE_Dos_and_Donts_for_investor_16_07_2024,
  Fabits_Rights_and_Obligations_DP,
  Fabits_Rights_and_Obligations_of_stock_brokers_16_07_2024,
  Fabits_Risk_Disclosure_Document_RDD_16_07_2024,
  General_Rules_or_regulations_of_investing,
  Most_Important_Terms_and_Conditions_MITC,
  PNP_in_Diff_Languages,
  Voluntary_Freezing_or_blocking_of_online_access_of_Trading_Account,
} from "../assets/investor_awareness";
import { Charter } from "../assets/fabits_assets";

const Data = [
  {
    id: 1,
    title: "Guidance Notes",
    pdf: Fabits_Guidance_note_for_investors_16_07_2024,
  },
  {
    id: 2,
    title: (
      <>
        <p>
          Do's And Dont's{" "}
          <span style={{ fontWeight: "bolder", fontSize: "18px" }}>- NSE</span>
        </p>
      </>
    ),
    pdf: Fabits_NSE_Dos_and_Donts_for_investor_16_07_2024,
  },
  {
    id: 3,
    title: (
      <>
        <p>
          Do's And Dont's{" "}
          <span style={{ fontWeight: "bolder", fontSize: "18px" }}>- CDSL</span>
        </p>
      </>
    ),
    pdf: Fabits_CDSL_Do_and_Donts_for_investor_16_07_2024,
  },
  {
    id: 4,
    title: (
      <>
        <p>
          Rights And Obligations{" "}
          <span style={{ fontWeight: "bolder", fontSize: "18px" }}>- NSE</span>
        </p>
      </>
    ),
    pdf: Fabits_Rights_and_Obligations_of_stock_brokers_16_07_2024,
  },
  {
    id: 5,
    title: (
      <>
        <p>
          Rights And Obligations{" "}
          <span style={{ fontWeight: "bolder", fontSize: "18px" }}>- CDSL</span>
        </p>
      </>
    ),
    pdf: Fabits_Rights_and_Obligations_DP,
  },
  {
    id: 6,
    title: "Risk disclosure Docs",
    pdf: Fabits_Risk_Disclosure_Document_RDD_16_07_2024,
  },
  {
    id: 7,
    title: "Most Important Terms And Conditions",
    pdf: Most_Important_Terms_and_Conditions_MITC,
  },
  {
    id: 8,
    title: "Voluntary Freezing / Blocking of Online Access",
    pdf: Voluntary_Freezing_or_blocking_of_online_access_of_Trading_Account,
  },
  {
    id: 9,
    title: "Online Account Closure",
    pdf: Account_closure_policy,
  },
  {
    id: 10,
    title: "Investors Directive",
    pdf: General_Rules_or_regulations_of_investing,
  },
  {
    id: 11,
    title: "Vernacular Languages",
    pdf: PNP_in_Diff_Languages,
  },
];

const InvestorAwareness = () => {
  return (
    <div className="IAcontainer">
      <Helmet>
        <title>Fabits - Investor's Awareness</title>
        <meta
          name="Investor's Awareness Page"
          content="Regulatory page displaying the investor Awareness of Shareway Securities Private Limited"
        />
      </Helmet>
      <h1 style={{ marginTop: "20px", fontSize: "3em" }}>
        Investor's Awareness
      </h1>

      {/* {Data.map((data) => (
        <div key={data.id}>
          <h1 style={{ fontSize: "2em", margin: "20px 0px 0px 0px" }}>
            {data.id}. {data.title}
          </h1>
        </div>
      ))} */}

      <Table tableData={Data} />

      <div style={{ marginTop: "20px" }}>
        <h2>Dos and Don’ts for Investors</h2>

        <p style={{ margin: "5px 0px 10px 0px" }}>
          For Do’s and Don’ts please refer to the &nbsp;
          <a href="https://www.cdslindia.com/Investors/InvestorCharter.html">
            link.
          </a>
        </p>

        <h2>Rights of investors</h2>

        <p style={{ margin: "5px 0px 10px 0px" }}>
          For rights, please refer to the &nbsp;
          <a href="https://www.cdslindia.com/Investors/InvestorCharter.html">
            link.
          </a>
        </p>
        <h2>Responsibilities of Investors</h2>
        <p style={{ margin: "5px 0px 10px 0px" }}>
          For responsibilities, please refer to the &nbsp;
          <a href="https://www.cdslindia.com/Investors/InvestorCharter.html">
            link.
          </a>
        </p>
        <h2>Process for Demat Account Opening</h2>
        <p style={{ margin: "5px 0px 10px 0px" }}>
          Please refer to the &nbsp;
          <a
            href={Charter.Process_for_Demat_Account_Opening}
            target="_blank"
            rel="noreferrer"
          >
            link
          </a>
          .
        </p>
        <h2>Procedure for filing a complaint on designated email id</h2>
        <p style={{ margin: "5px 0px 10px 0px" }}>
          Please refer to the &nbsp;
          <a
            href={
              Charter.Procedure_for_filing_a_complaint_on_designated_email_id
            }
            target="_blank"
            rel="noreferrer"
          >
            link
          </a>
          .
        </p>
        <h2>Procedure for finding out the status of the Complaint</h2>
        <p style={{ margin: "5px 0px 10px 0px" }}>
          Please refer to the{" "}
          <a
            href={Charter.Procedure_for_finding_out_the_status_of_the_Complaint}
            target="_blank"
            rel="noreferrer"
          >
            link
          </a>
          .
        </p>
        <h2>Basic details of the SB/DP & Key Managerial Personnels details</h2>
        <p style={{ margin: "5px 0px 10px 0px" }}>
          Please refer to the{" "}
          <a
            href={Charter.Basic_details_Key_Managerial_Personnels_details}
            target="_blank"
            rel="noreferrer"
          >
            link
          </a>
        </p>
      </div>
    </div>
  );
};

export default InvestorAwareness;
