import React from 'react'
import styles from "./Review.module.css"

const Review = () => {
    return (
        <>
            <div className={styles.revcontainer}>
                <div className={styles.content}>
                    <span>This free review is for you if you are: </span>
                    <p>📉 Uncertain about savings and investments<br />

                        👪 Unsure if you’re adequately insured<br />

                        🏛️ Not maximizing tax savings<br />

                        💰 Worried about high loan interest rates</p>
                </div>
                <div className={styles.suit}>
                    <p>Suitable for: </p>
                    <p>👨‍💼 Corporate Employees</p>
                    <p>🏪 Business Owners</p>
                    <p>👨‍💻 Freelancers</p>
                </div>
            </div>
        </>
    )
}

export default Review;