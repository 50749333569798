import React from "react";
import styles from "./getInTouch.module.css";

const url =
  "https://wa.me/+918217801975?text=I'm+interested+in+opening+an+NPS+account+with+Fabits+and+ICICI.+Can+you+help+me+understand+the+offering+better?";

const GetInTouch = () => {
  return (
    <div className={styles.container}>
      <h1>Confused which plan to pick? </h1>
      <p>Get in touch with us for complete, dedicated assistance</p>
      <div className={styles.btn}>
        <button onClick={() => window.open(url, "_blank")}>
          <span>Get in touch</span>
        </button>
      </div>
    </div>
  );
};

export default GetInTouch;
