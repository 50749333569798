import React, { useEffect } from "react";
import "./Termsandconditions.css";
import { Helmet } from "react-helmet";
import ReactGA4 from "react-ga4";
import { useLocation } from "react-router-dom";
import clevertap from "clevertap-web-sdk";

function Disclosure() {
  const location = useLocation();
  const currentYear = new Date().getFullYear();
  useEffect(() => {
    ReactGA4.send("pageview", location.pathname);
    clevertap.event.push("Page Viewed", {
      Page_name: "Tax Filing Landing Page",
    });
  }, []);
  return (
    <>
      <div className="termsandconditionspage">
        <Helmet>
          <title>Fabits - Disclosure</title>
          <meta
            name="description"
            content="Regulatory page displaying the disclosure requirements of Shareway Securities Private Limited"
          />
        </Helmet>
        {/* <div className="termsandconditionsheeding">
          <h1 className="headingoftandc">Disclosure</h1>
        </div> */}
        <div className="contentstartshere">
          <h2 className="sharewaysecuritieslimited">
            Shareway Securities Private Limited (FABITS)
          </h2>
          <br />
          <p>
            The site, including any content or information contained within it
            or any site linked to the site, or any product or service licensed
            or purchased through the site, is provided on an “as is” basis and
            without warranties of any kind, either express or implied,
            including, but not limited to warranties of title or
            non-infringement or warranties of merchantability and fitness for a
            particular purpose, other than those warranties which are implied by
            and incapable of exclusion, restriction or modification under the
            laws applicable to this agreement. The website is owned, operated
            and maintained by Fabits (hereinafter referred to as “Shareway
            Securities Limited” a Company incorporated under the Companies Act,
            2013 having CIN: U67120TN2008PLC066952, and having its Registered
            Office at #Flat E,2nd Floor“Gilli Flower” No 6/46,23rd Street L
            Block Anna Nagar East Chennai – 600102
            <br />
            <br />
            You acknowledge that any warranty that is provided in connection
            with any of the products or services described herein is provided
            solely by the owner, advertiser, manufacturer, or supplier of that
            product and/or service, and not by Fabits.Fabits.com does not
            warrant that your access to the site and/or related services will be
            uninterrupted or error-free, that defects will be corrected, or that
            this site or the server provided herein is free of viruses or other
            harmful components. Access to and use of this site and the
            information provided herein is at your own risk and Fabits.com does
            not undertake any accountability for any irregularities, viruses or
            damage to any computer that results from accessing, availing or
            downloading of any information from this site. Fabits.com does not
            warrant or make any representations regarding the use of or any
            consequences resulting from the use of any product and/or service
            purchased in terms of its compatibility, correctness, accuracy, and
            reliability or otherwise. You assume total responsibility and risk
            for your use of this site and all site-related services.
            <br />
            <br />
            You agree that, except as provided under the Fabits.com return
            policy, Fabits.com and its directors, officers, employees, agents,
            sponsors, consultants, business partners or other representatives
            (‘service providers’) shall not be responsible or liable for any
            direct, indirect, incidental, consequential, special, exemplary,
            punitive or any other damages (including without limitation loss of
            profits, loss or corruption of data, loss of goodwill, work
            stoppage, computer failure or malfunction, or interruption of
            business) under any contract, negligence, strict liability or any
            other theory arising out of or relating in any way with the use of
            the site or in reliance of the information available on the site,
            site-related services, or any products or services offered or sold
            or displayed on the Fabits.com site.
            <br />
            <br />
            If the foregoing limitation is held to be unenforceable, the maximum
            liability of Fabits. and its service providers to you shall not
            exceed the amount of fees paid by you for the products or services
            that you have ordered through the site.
            <br />
            <br />
            The possibility exists that the site could include inaccuracies or
            errors. Additionally, the possibility exists that unauthorized
            additions, deletions or alterations could be made by third parties
            to the site. Although Fabits. attempts to ensure the highest level
            of integrity, correctness, and authenticity of the site, it makes no
            guarantees whatsoever as to its completeness, correctness, or
            accuracy. In the event that any inaccuracy arises, please inform
            Fabits. so that it can be corrected.
            <br />
            <br />
            The price and value of investments and the income derived from them
            can increase or decrease and you may not get back the amount you
            invest. Changes in the rate of exchange may have an adverse effect
            on the value, price, and income of investments in deposits other
            than your own. Past performance is not necessarily an indicator of
            future performance. The services and investments referred to in our
            site may have tax consequences and it is important to bear in mind
            that Fabits. does not provide tax advice. The level of taxation
            depends on individual circumstances and such levels and bases of
            taxation can change. You should consult your own tax advisor in
            order to understand any applicable tax consequences.
            <br />
            <br />
            Fabits. does not make any personal recommendations. The information
            on our Internet site is provided solely to enable investors to make
            their own investment decisions and does not constitute a
            recommendation to buy, sell or otherwise deal in investments. The
            services and the securities we offer may not be suitable for all
            customers. If you have any doubts, you should seek advice from an
            independent financial adviser.
            <br />
            <br />
            This Internet site is only available to residents in India and is
            presently not available to foreign residents including non-resident
            Indians (NRIs) in foreign jurisdictions where the services cannot be
            offered without prior regulatory compliance. In all such cases due
            efforts are being made to comply with regulatory requirements in
            various jurisdictions of which you shall be notified from time to
            time. This service does not constitute an offer to sale or a
            solicitation to any person in any jurisdiction where it is unlawful
            to make such an offer or solicitation.
            <br />
            <br />
            No market analysis, research report or any other information, on the
            web site is to be construed as a representation with respect to
            shares, securities, or other investments.
            <br />
            <br />
            You understand and agree that no joint venture, partnership,
            employment, or agency relationship exists between you and us as a
            result of this agreement or on account of use of our web site.
            <br />
            <br />
            Price and availability of products and services offered on the site
            are subject to change without prior notice. To the extent that we
            provide information on the availability of products or services you
            should not rely on such information. Fabits.com will not be liable
            for any lack of availability of products and services you may order
            through the site.
            <br />
            <br />
          </p>
          <br />
          <h4 className="bigBoldFont">
            Disclaimer and Limitation of Liability
          </h4>
          <br />
          <p>
            Disclaimer: Fabits and/or any of its affiliates do not provide any
            tips, advisory, solicitation, opinions, or portfolio management
            services regarding buying, selling and trading of any securities,
            directly or indirectly, in any manner. Information displayed or laid
            out by Fabits, including but not limited to; Fabits Varsity,
            trading, charting and other tools on the and Pi platform Open trade,
            Android, iOS, Pulse, and all other platforms including social media,
            are purely for either educational, informational, or discussion
            purposes and should not be construed as direct or indirect
            recommendation to invest, trade or speculate in the markets. Fabits
            would welcome and appreciate immediate notification or notice, if
            any person would come across any such tips, advisory, solicitation,
            opinions, or related services regarding buying, selling and trading
            of any securities, directly or indirectly, in any manner from any
            person or platform which is believed to be or likely to be believed
            as Fabits. Please contact
            <a href="mailto:support@fabits.com">support@fabits.com</a>
            for any of your queries with regard to the same. In no event shall
            Fabits be liable for any investments, trades or speculative
            activities performed by any person based on any such information or
            content and all such activities shall be solely at their own risk.
            <br />
            <br />
            Transactions between you and Fabits. shall be governed by and
            construed in accordance with the laws of India, without regard to
            any conflicts of laws of other nations. Any litigation regarding
            this agreement or any transaction between customer and Fabits.com or
            any action at law or in equity arising out of or relating to this
            agreement or transaction shall be filed only in the Competent Courts
            of Bangalore alone and the customer hereby agrees, consents and
            submits to the jurisdiction of such courts for the purpose of
            litigating any such action.
            <br />
            <br />
            The Stock exchange, Mumbai is not in any manner answerable,
            responsible or liable to any person pr persons for any acts of
            omissions or commission, errors, mistakes and/or partners, agents
            associate etc., of any of the Rules, regulations, bye-laws of the
            Stock Exchange Mumbai, SEBI Act or any other laws in force from time
            to time. The Stock Exchange, Mumbai is not answerable, responsible,
            or liable for any information on this website or for any services
            rendered by us, our employees and our servants.
            <br />
            <br />
            Please note that any of your personal details; including but not
            limited to, your registered contact number, password, login
            information, email ID, date of birth, bank account, address etc., is
            strictly not shared with any third party,i.e. any non-affiliate of
            Fabits, without your express consent.
            <br />
            <br />
            Fabits or any of its affiliates, employees, agents, or any other
            representatives would never contact you and ask you for your
            personal details such as your login information, password, Aadhaar
            OTP, bank account details and such other related information. Please
            do not provide any details to queries from calls where such
            information is asked by the caller who impersonates to be an
            affiliate of Fabits. For reporting any such information or for
            further queries with regard to this, please contact
            support@Fabits.com.
            <br />
            <br />
            Financial information
            <span className="copyrightssection">
              &copy; 2010 – {currentYear}{" "}
            </span>
            <br />
            <br />
            Notices & transcripts
            <b> Notice of Annual General Meeting</b>
          </p>
        </div>
      </div>
    </>
  );
}

export default Disclosure;
