import React, { useState } from "react";
import styles from "./features.module.css";
import logo from "../../../../assets/Marketing_landing_Pages/Features/logo.svg";
import NpsModel from "../NPSModel/NpsModel";

const Features = () => {
  const [ppf, setPpf] = useState(false);
  const [isModalOpen, setIsModelOpen] = useState(false);
  return (
    <>
      <div className={styles.featureContent}>
        <div className={styles.container}>
          <h1>
            Here’s why NPS is becoming a popular investment tool for retirement
            planning
          </h1>
        </div>

        <div className={styles.featuresContent}>
          <div>
            <h1>List of features</h1>
            <p>Let’s see which one gets you what</p>

            <ul>
              <li>Diversification</li>
              <li>Returns</li>
              <li>Risk</li>
              <li>Tax efficiency</li>
              <li>Pension</li>
              <li>Withdrawal</li>
            </ul>
          </div>

          <div>
            <div>
              <h1>NPS on Fabits</h1>

              <div>
                <p>In partnership with</p>
                <img src={logo} alt="icici logo" />
              </div>
            </div>

            <ul>
              <li>High</li>
              <li>12-14%</li>
              <li>Medium</li>
              <li>Both old & new regime</li>
              <li>40% withdrawal mandatory via pension</li>
              <li>Simple & easy Digitally from NPS account</li>
            </ul>

            <div>
              <button
                onClick={() => {
                  setIsModelOpen(true);
                }}
              >
                <span>Plan with Fabits</span>
              </button>
            </div>
          </div>

          <div></div>

          <div>
            <h1>EPF</h1>

            <ul>
              <li>Low</li>
              <li>8.25%</li>
              <li>Low</li>
              <li>Old regime only</li>
              <li>No pension</li>
              <li>
                Cumbersome <br /> 3 month waiting period
              </li>
            </ul>
          </div>

          <div>
            <h1>PPF</h1>

            <ul>
              <li>Low</li>
              <li>7.1%</li>
              <li>Low</li>
              <li>Old regime only</li>
              <li>No pension</li>
              <li>Cumbersome</li>
            </ul>
          </div>
        </div>

        <div className={styles.featuresContent_tab}>
          <div>
            <div className={styles.heading}>
              <h1>NPS on Fabits</h1>
              <div>
                <p>in partnership with</p>
                <img src={logo} alt="icici logo" />
              </div>
            </div>
            <div className={styles.feature}>
              <div>
                <div>
                  <div>
                    <span class="material-symbols-rounded">water_drop</span>
                  </div>
                  <div>
                    <p>Diversification </p>
                    <p>High</p>
                  </div>
                </div>
              </div>
              <div>
                <div>
                  <div>
                    <span class="material-symbols-rounded">timeline</span>
                  </div>
                  <div>
                    <p>Returns</p>
                    <p>Moderate</p>
                  </div>
                </div>
                <p>12-14%</p>
              </div>
              <div>
                <div>
                  <div>
                    <span class="material-symbols-rounded">
                      local_fire_department
                    </span>
                  </div>
                  <div>
                    <p>Risk</p>
                    <p>Medium</p>
                  </div>
                </div>
              </div>
              <div>
                <div>
                  <div>
                    <span class="material-symbols-rounded">
                      assured_workload
                    </span>
                  </div>
                  <div>
                    <p>Tax efficiency</p>
                    <p>Both old & new regime</p>
                  </div>
                </div>
                {/* <p>Capital gains tax</p> */}
              </div>
              <div>
                <div>
                  <div>
                    <span class="material-symbols-rounded">
                      volunteer_activism
                    </span>
                  </div>
                  <div>
                    <p>Pension</p>
                    <p>40% withdrawal mandatory via pension</p>
                  </div>
                </div>
              </div>
              <div>
                <div>
                  <div>
                    <span class="material-symbols-rounded">
                      volunteer_activism
                    </span>
                  </div>
                  <div>
                    <p>Withdrawal</p>
                    <p>Simple & easy Digitally from NPS account</p>
                  </div>
                </div>
                {/* <p>Via stock market</p> */}
              </div>
            </div>
            <div
              className={styles.nextBtn}
              onClick={() => {
                setIsModelOpen(true);
              }}
            >
              <p>Plan with Fabits</p>
              <span class="material-symbols-rounded">arrow_right_alt</span>
            </div>
          </div>

          <div>
            <div>
              <div>EPF</div>

              <div className={styles.featureEPF}>
                <div>
                  <div>
                    <p>Low</p>
                  </div>
                </div>
              </div>
              <div className={styles.featureEPF}>
                <div>
                  <div>
                    <p>8.25%</p>
                  </div>
                </div>
              </div>
              <div className={styles.featureEPF}>
                <div>
                  <div>
                    <p>Low</p>
                  </div>
                </div>
              </div>
              <div className={styles.featureEPF}>
                <div>
                  <div>
                    <p>Old regime only</p>
                  </div>
                </div>
              </div>
              <div className={styles.featureEPF}>
                <div>
                  <div>
                    <p>No pension</p>
                  </div>
                </div>
              </div>
              <div className={styles.featureEPF}>
                <div>
                  <div>
                    <p>Cumbersome 3 month waiting period</p>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <div>PPF</div>

              <div className={styles.featureEPF}>
                <div>
                  <div>
                    <p>Low</p>
                  </div>
                </div>
              </div>
              <div className={styles.featureEPF}>
                <div>
                  <div>
                    <p>7.1%</p>
                  </div>
                </div>
              </div>
              <div className={styles.featureEPF}>
                <div>
                  <div>
                    <p>Low</p>
                  </div>
                </div>
              </div>
              <div className={styles.featureEPF}>
                <div>
                  <div>
                    <p>Old regime only</p>
                  </div>
                </div>
              </div>
              <div className={styles.featureEPF}>
                <div>
                  <div>
                    <p>No pension</p>
                  </div>
                </div>
              </div>
              <div className={styles.featureEPF}>
                <div>
                  <div>
                    <p>Cumbersome</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.featuresContent_mobile}>
          <div>
            <div className={styles.heading}>
              <h1>NPS on Fabits</h1>
              <div>
                <p>in partnership with</p>
                <img src={logo} alt="icici logo" />
              </div>
            </div>
            <div className={styles.feature}>
              <div>
                <div>
                  <div>
                    <span class="material-symbols-rounded">water_drop</span>
                  </div>
                  <div>
                    <p>Diversification </p>
                    <p>High</p>
                  </div>
                </div>
              </div>
              <div>
                <div>
                  <div>
                    <span class="material-symbols-rounded">timeline</span>
                  </div>
                  <div>
                    <p>Returns</p>
                    <p>Moderate</p>
                  </div>
                </div>
                <p>12-14%</p>
              </div>
              <div>
                <div>
                  <div>
                    <span class="material-symbols-rounded">
                      local_fire_department
                    </span>
                  </div>
                  <div>
                    <p>Risk</p>
                    <p>Medium</p>
                  </div>
                </div>
              </div>
              <div>
                <div>
                  <div>
                    <span class="material-symbols-rounded">
                      assured_workload
                    </span>
                  </div>
                  <div>
                    <p>Tax efficiency</p>
                    <p>Both old & new regime</p>
                  </div>
                </div>
                {/* <p>Capital gains tax</p> */}
              </div>
              <div>
                <div>
                  <div>
                    <span class="material-symbols-rounded">
                      volunteer_activism
                    </span>
                  </div>
                  <div>
                    <p>Pension</p>
                    <p>40% withdrawal mandatory via pension</p>
                  </div>
                </div>
              </div>
              <div>
                <div>
                  <div>
                    <span class="material-symbols-rounded">
                      volunteer_activism
                    </span>
                  </div>
                  <div>
                    <p>Withdrawal</p>
                    <p>Simple & easy Digitally from NPS account</p>
                  </div>
                </div>
                {/* <p>Via stock market</p> */}
              </div>
            </div>

            <div
              onClick={() => {
                setIsModelOpen(true);
              }}
              className={styles.nextBtn}
            >
              <p>Start Now</p>
              <span class="material-symbols-rounded">arrow_right_alt</span>
            </div>
          </div>

          <div>
            {!ppf ? (
              <div>
                <div>PPF</div>

                <div className={styles.featureEPF}>
                  <div>
                    <div>
                      <p>Low</p>
                    </div>
                  </div>
                </div>
                <div className={styles.featureEPF}>
                  <div>
                    <div>
                      <p>7.1%</p>
                    </div>
                  </div>
                </div>
                <div className={styles.featureEPF}>
                  <div>
                    <div>
                      <p>Low</p>
                    </div>
                  </div>
                </div>
                <div className={styles.featureEPF}>
                  <div>
                    <div>
                      <p>Old regime only</p>
                    </div>
                  </div>
                </div>
                <div className={styles.featureEPF}>
                  <div>
                    <div>
                      <p>No pension</p>
                    </div>
                  </div>
                </div>
                <div
                  className={styles.featureEPF}
                  style={{ marginTop: "20px" }}
                >
                  <div>
                    <div>
                      <p>Cumbersome</p>
                    </div>
                  </div>
                </div>

                <div
                  className={`${styles.nextCardBtn} ${styles.left}`}
                  onClick={() => setPpf(!ppf)}
                >
                  <span class="material-symbols-rounded">arrow_back_ios</span>
                </div>

                <div
                  className={`${styles.nextCardBtn} ${styles.right}`}
                  onClick={() => setPpf(!ppf)}
                >
                  <span class="material-symbols-rounded">
                    arrow_forward_ios
                  </span>
                </div>
              </div>
            ) : (
              <div>
                <div>EPF</div>

                <div className={styles.featureEPF}>
                  <div>
                    <div>
                      <p>Low</p>
                    </div>
                  </div>
                </div>
                <div className={styles.featureEPF}>
                  <div>
                    <div>
                      <p>8.25%</p>
                    </div>
                  </div>
                </div>
                <div className={styles.featureEPF}>
                  <div>
                    <div>
                      <p>Low</p>
                    </div>
                  </div>
                </div>
                <div className={styles.featureEPF}>
                  <div>
                    <div>
                      <p>Old regime only</p>
                    </div>
                  </div>
                </div>
                <div className={styles.featureEPF}>
                  <div>
                    <div>
                      <p>No pension</p>
                    </div>
                  </div>
                </div>
                <div
                  className={styles.featureEPF}
                  style={{ marginTop: "20px" }}
                >
                  <div>
                    <div>
                      <p>Cumbersome + 3 month waiting period</p>
                    </div>
                  </div>
                </div>

                <div
                  className={`${styles.nextCardBtn} ${styles.left}`}
                  onClick={() => setPpf(!ppf)}
                >
                  <span class="material-symbols-rounded">arrow_back_ios</span>
                </div>

                <div
                  className={`${styles.nextCardBtn} ${styles.right}`}
                  onClick={() => setPpf(!ppf)}
                >
                  <span class="material-symbols-rounded">
                    arrow_forward_ios
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <NpsModel isModalOpen={isModalOpen} setIsModelOpen={setIsModelOpen} />
    </>
  );
};

export default Features;
