import React, { useState, useEffect } from "react";
import styles from "./TopSection.module.css";

const TopSection = () => {
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [deviceType, setDeviceType] = useState("");
    const [formData, setFormData] = useState({
        fullName: "",
        email: "",
        phone: "",
        preferredTime: "Between 9 AM and 12 PM", // Default value for time
        preferredDay: "Monday", // Default value for day
    });

    useEffect(() => {
        // Detect device
        const userAgent = navigator.userAgent.toLowerCase();
        if (/android/i.test(userAgent)) {
            setDeviceType("android");
        } else if (/iphone|ipad|ipod/i.test(userAgent)) {
            setDeviceType("ios");
        } else {
            setDeviceType("desktop");
        }
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await fetch("https://apiuat-mywealth.fabits.com/customerservice/api/financialReview", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(formData),
            });

            const responseText = await response.text(); // Get the raw response as text

            if (response.ok) {
                try {
                    const responseData = JSON.parse(responseText); // Try parsing as JSON
                    console.log("API Response:", responseData);
                    setIsSubmitted(true);
                } catch (error) {
                    console.error("Error parsing JSON:", error);
                    console.log("Raw response:", responseText);
                    // Handle the case where the response is not valid JSON
                    setIsSubmitted(true);
                }
            } else {
                console.error("Error submitting the form data", response.status);
                setIsSubmitted(false);
            }
        } catch (error) {
            console.error("API Error:", error);
            setIsSubmitted(true);
        }
    };


    // Function to handle redirect based on device type
    const handleRedirect = () => {
        if (deviceType === "android") {
            window.location.href = "https://play.google.com/store/apps/details?id=com.fabits.mywealth&hl=en_IN";
        } else if (deviceType === "ios") {
            window.location.href = "https://apps.apple.com/ca/app/fabits/id1600230367"; // replace with your actual iOS App Store link
        } else {
            window.location.href = "https://mywealth.fabits.com/sign-up"; // redirect to signup page for desktop
        }
    };

    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <div className={styles.left}>
                    <p>Your finances need cleaning too!</p>
                    <span>Get a FREE 1-on-1 review of your money from professionals</span>
                </div>

                <div className={styles.formContainer}>
                    {!isSubmitted ? (
                        <form className={styles.form} id="formSection" onSubmit={handleSubmit}>
                            <h3>Get your finances reviewed for free</h3>
                            <label>Full name</label>
                            <input
                                type="text"
                                placeholder="Dhruv Jain"
                                required
                                name="fullName"
                                value={formData.fullName}
                                onChange={handleChange}
                            />

                            <label>Email address</label>
                            <input
                                type="email"
                                placeholder="dhruv.jain@email.com"
                                required
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                            />

                            <label>Phone number</label>
                            <input
                                type="tel"
                                placeholder="+91"
                                required
                                name="phone"
                                value={formData.phone}
                                onChange={handleChange}
                            />

                            <label>Preferred Time</label>
                            <select
                                required
                                name="preferredTime"
                                value={formData.preferredTime}
                                onChange={handleChange}
                            >
                                <option>Between 9 AM and 12 PM</option>
                                <option>Between 12 PM and 5 PM</option>
                                <option>Between 5 PM and 10 PM</option>
                            </select>

                            <label>Preferred Day</label>
                            <select
                                required
                                name="preferredDay"
                                value={formData.preferredDay}
                                onChange={handleChange}
                            >
                                <option>Monday</option>
                                <option>Tuesday</option>
                                <option>Wednesday</option>
                                <option>Thursday</option>
                                <option>Friday</option>
                                <option>Saturday</option>
                            </select>

                            <button type="submit" >
                                {"Get Started"}
                            </button>
                        </form>
                    ) : (
                        <div className={styles.thankYouMessage}>
                            <h3>Thank you!</h3>
                            <p>We’ll be in touch shortly. In the meantime,</p>
                            <button className={styles.appButton} onClick={handleRedirect}>
                                {deviceType === "android"
                                    ? "Get on Google Play"
                                    : deviceType === "ios"
                                        ? "Download on App Store"
                                        : "Sign Up"}
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default TopSection;
