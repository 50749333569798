import React from "react";
import styles from "./talkToUs.module.css";

const url =
  "https://wa.me/+918217801975?text=I'm+interested+in+opening+an+NPS+account+with+Fabits+and+ICICI.+Can+you+help+me+understand+the+offering+better?";

const TalkToUs = () => {
  return (
    <div className={styles.container}>
      <h1>Build a smart retirement plan</h1>
      <p>Talk to us to build a plan that’s most suitable for you</p>

      <div className={styles.TalkToUsBTn}>
        <button onClick={() => window.open(url, "_blank")}>
          <span>Talk to us</span>
        </button>
      </div>
    </div>
  );
};

export default TalkToUs;
