import React, { useState } from "react";
import styles from "./Accordion.module.css";

const Accordian = ({ title, desc }) => {
  const [isActive, setIsActive] = useState(false);

  return (
    <div>
      <div
        className={`${styles.accordion} ${isActive ? styles.active : ""}`}
        onClick={() => setIsActive(!isActive)}
      >
        {isActive ? (
          <span
            className="material-symbols-rounded"
            onClick={() => setIsActive(false)}
          >
            do_not_disturb_on
          </span>
        ) : (
          <span
            className="material-symbols-rounded"
            onClick={() => setIsActive(true)}
          >
            add_circle
          </span>
        )}

        {title}
      </div>
      <div
        className={styles.panel}
        style={{ display: isActive ? "block" : "none" }}
      >
        {desc}
      </div>
    </div>
  );
};

export default Accordian;
