
import React from "react";
import styles from "./Testimony.module.css";
import BWomen from "../../../../assets/Diwali_LandingPage/Testimony/3.svg";
import businessman_2 from "../../../../assets/Diwali_LandingPage/Testimony/1.svg";
import businessman_3 from "../../../../assets/Diwali_LandingPage/Testimony/2.svg";
import businessman_4 from "../../../../assets/Diwali_LandingPage/Testimony/4.svg";
import Card from "./Card";

const testimonyData = [
    {
        id: 1,
        user: "Anjali",
        age: "32",
        role: "HR Manager",
        image: businessman_2,
        content:
            "I used to think I was 'saving enough' just by stashing money in my account, but this session opened my eyes. Now, I'm actively planning for my future, not just hoping for the best",
    },
    {
        id: 2,
        user: "Vikram",
        age: "38",
        role: "Project Lead",
        image: BWomen,
        content:
            "I’ve got investments, loans, and insurance policies—frankly, it was a mess. This session finally helped me connect the dots and see the big picture. I feel more in control now.",
    },
    {
        id: 3,
        user: "Rhea.",
        role: "Marketing Specialist",
        age: "29",
        image: businessman_4,
        content:
            "For the first time, someone explained things in plain, simple language. I used to feel like finance was just for experts, but now, I actually understand how to make my money work harder",
    },
    {
        id: 4,
        user: "Arjun",
        role: "IT Consultant",
        age: "42",
        image: businessman_3,
        content:
            "I was shocked to realize how much I could save on taxes with just a few smart moves. The best part? It was practical, no jargon—just clear, helpful advice"
    }
];

const Testimony = () => {
    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <h1>What others are saying about their <br /> financial review</h1>

                <div className={styles.cardContainer}>
                    {testimonyData.map((testimony) => {
                        const { id, user, age, role, image, content, rating } = testimony;
                        return (
                            <Card
                                id={id}
                                user={user}
                                age={age}
                                role={role}
                                image={image}
                                content={content}
                                rating={rating}
                            />
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default Testimony;
