import React from "react";
import styles from "./Ready.module.css";


const loginUrl = "https://mywealth.fabits.com/login?redirect=/dashboard/goldbonds";

const Ready = () => {
    const handleButtonClick = () => {
        // Open the login page in a new tab and wait for login to complete
        window.open(loginUrl, "_blank");
    };

    return (
        <div className={styles.container}>
            <h1>Ready to get started?</h1>
            <div className={styles.btn}>
                <button onClick={handleButtonClick}>
                    <span>Explore Gold ETFs ✨</span>
                </button>
            </div>
        </div>
    );
};

export default Ready;
