import React from "react";
import styles from "./Gold.module.css";
import Features from "./Features/Features";
import TopSection from "./TopSection/TopSection";
import AboutUs from "./AboutUs/AboutUs";
import FAQ from "./FAQ/FAQ";
import Fund from "./Emergency/Fund";
import Ready from "./AboutUs/ReadyTo/Ready";

const Gold = () => {
  return (
    <div className={styles.container}>
      <TopSection />
      <div className={styles.content}>
        <Features />
        <Fund />
        <AboutUs />
        <Ready />
        <FAQ />
      </div>
      <div className={styles.getInTouch}>
        {/* <GetInTouch /> */}
      </div>
    </div>
  );
};

export default Gold;
