import React from 'react';
import styles from "./TopSection.module.css";
import DiwaliImg from "../../../assets/Gold_ETF_Page/Gold_bar.svg";

const loginUrl = "https://mywealth.fabits.com/login?redirect=/dashboard/theme-details";

const TopSection = () => {
    const handleButtonClick = () => {

        window.open(loginUrl, "_blank");
    };

    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <div className={styles.left}>
                    <span>Investing in gold?<br />
                        Do it the smart way!</span>
                    <p>Explore secure investments in Gold ETFs starting at just ₹12</p>
                    <div className={styles.freecall}>
                        <button className={styles.btn} onClick={handleButtonClick}>
                            <span>Explore Gold ETFs ✨</span>
                        </button>
                    </div>
                </div>
                <div className={styles.Diwali_Img}>
                    <img src={DiwaliImg} alt='Diwali_Img' />
                </div>
            </div>
        </div>
    );
}

export default TopSection;
