import React, { useEffect } from "react";
import "./Termsandconditions.css";
import { Helmet } from "react-helmet";
import ReactGA4 from "react-ga4";
import { useLocation } from "react-router-dom";
import clevertap from "clevertap-web-sdk";

function PrivacyPolicy() {
  const location = useLocation();
  const currentYear = new Date().getFullYear();

  useEffect(() => {
    ReactGA4.send("pageview", location.pathname);
    clevertap.event.push("Page Viewed", {
      Page_name: "Privae policy page",
    });
  }, []);

  return (
    <>
      <div className="termsandconditionspage">
        <Helmet>
          <title>Fabits - Privacy Policy</title>
          <meta
            name="description"
            content="Regulatory page displaying the privacy policy adopted by Shareway Securities Private Limited"
          />
        </Helmet>
        {/* <div className="termsandconditionsheeding">
          <h1 className="headingoftandc">
            <span className="font__green">Privacy </span>
            Policy
          </h1>
        </div> */}
        <div className="contentstartshere">
          <h2 className="sharewaysecuritieslimited">
            Shareway Securities Private Limited (FABITS)
          </h2>
          <p className="mt-5">
            In the course of using this website or any of the websites under the
            ‘Shareway Securities Private Limited’ domain or availing the
            products and services vide the online application forms and
            questionnaires, online consents and such other details required from
            time to time on any of Shareway Securities Private Limited’s (and/or
            its affiliates) web platforms or mobile applications, Shareway
            Securities Private Limited and/or its affiliates may become privy to
            some of your personal information, including which may or may not be
            of confidential nature. Shareway Securities Private Limited is
            strongly committed to protecting the privacy of its users/clients
            and has taken all necessary and reasonable measures to protect the
            confidentiality of any customer information.
          </p>
          <br></br>
          <p>
            For the purpose of these Privacy Policy, wherever the context so
            mentions “Covered Persons”, “Client”, “You” or “Your”, it shall mean
            any natural or legal person who has visited this website/platform
            and/or has agreed to or has enquired open an account and/or
            initiated the process of opening an account with Shareway Securities
            Limited (also defined as “Covered Persons”).
          </p>
          <br></br>
          <p>
            Shareway Securities Private Limited allows any person to
            use/visit/browse the website without registering on the website. The
            term “We”, “Us”, “Our” and “Team Shareway Securities Private
            Limited” shall mean Shareway Securities Private Limited (Formerly
            Known As Shareway Securities Limited) (hereinafter shall be referred
            together as ‘Shareway Securities Private Limited’). Shareway
            Securities Private Limited is a Trading Member of NSE, registered
            with Securities & Exchange Board of India (“SEBI”) and having
            Registration No. INZ000208134. This website is owned, operated, and
            maintained by Shareway Securities Private Limited (Formerly Known As
            Shareway Securities Limited), a Company incorporated under the
            Companies Act, 2013 having CIN: U67120TN2008PLC066952, and having
            its Registered Office at #Flat E,2nd Floor, Gilli Flower” No
            6/46,23rd Street L Block Anna Nagar East Chennai 600102
          </p>
          <br></br>
          <p>
            Access to Your Shareway Securities Private Limited account and the
            usage of Your account is subject to Your compliance with all the
            terms and conditions set forth herein, read along with all
            documents, including but not limited to; applications forms and
            undertakings, signed by you during account opening, and any
            terms/consents/policies included on Fabits’s website/domain, i.e.
            www.fabits.com, including its “Terms and Conditions”, “Policies &
            Procedures”, and “Disclosure”.
          </p>
          <br></br>

          <h2>Scope of this Policy</h2>
          <br></br>
          <p>
            Any natural or legal person who has visited the Fabits
            website/platform and/or the mobile application, and/or who may or
            may not have further agreed to initiate the process of opening an
            account with Shareway Securities Private Limited shall come under
            the purview of the scope of this Privacy Policy. Shareway Securities
            Limited shall at all times follow all such rules prescribed under
            the Information Technology Act, 2000, and rules & procedural
            guidelines set by the Securities Exchange Board of India (“SEBI”)
            and/or any of its regulated entities/intermediaries that Shareway
            Securities Limited is a member of.
          </p>
          <br></br>
          <h2>Collection and use of your personal information</h2>
          <br></br>
          <p>
            Shareway Securities Private Limited may or may not collect your
            Personal Information to provide services on its web-based platform
            or mobile application. While opening a trading and Demat account
            with Shareway Securities Private Limited (Formerly Known As Shareway
            Securities Limited), you may be asked for certain Sensitive Personal
            Data or Information (as defined under the Information Technology
            Act, 2000) to complete your KYC (as per the ‘Know Your Client’
            requirements set by SEBI) and as per the Prevention of Money
            Laundering Act, 2002.
          </p>
          <br></br>
          <p>
            Shareway Securities Private Limited, may or may not, request for
            sensitive information from You at the time of account opening, which
            would include but not be limited to, (i) setting a password; (ii)
            financial information such as Bank account or credit card or debit
            card or other payment instrument details; (iii) information such as
            name, contact number, gender, date of birth, residence and
            employment addresses, father’s/mother’s and spouses names, bank
            account details, scans/copies of document proofs of address &
            identity such as Photograph, PAN, passport, drivers license, Aadhaar
            (voluntarily), etc. (iv) uploading signature proof/photo etc. All
            such information would be for the purpose of: identity verification
            and background checking for account opening as per KYC & AML Rules,
            personalising services offered to you, keeping you updated on our
            products and services relevant to you, information pertaining to our
            services you’ve subscribed to, and for any legal and regulatory /
            audit purposes.
          </p>
          <br></br>
          <p>
            Under Regulation 5 of the Information Technology (Reasonable
            Security and Procedures and Sensitive Personal Data or Information)
            Rules, 2011, Shareway Securities Private Limited is obliged to
            provide every registered user of Shareway Securities Private Limited
            with a readable copy of the personal information you have provided
            us.
          </p>
          <br></br>
          <p>
            We would save such information as required by the law, and as
            directed by the regulators along with such intermediaries regulated
            by them, for the purpose of offering You our services, and for such
            further regulatory, audit, and business purposes. We collect
            Personal Information from you only when you voluntarily use the
            services provided by Shareway Securities Private Limited, and/or
            enquire to begin an association with Shareway Securities Private
            Limited and/or complete the process of opening an account with
            Shareway Securities Private Limited (Formerly Known As Shareway
            Securities Limited).
          </p>
          <br></br>
          <p>
            With regard to security, we respect all information provided to us,
            and take all reasonable steps towards protection of the same. We
            have implemented technology and policies, with the objective of
            protecting your privacy from unauthorized access and improper use,
            and periodically review the same. We maintain procedural safeguards
            to protect the confidentiality and security of personally
            identifiable information transmitted to us.
          </p>
          <br></br>

          <h2>Aadhaar user consent policy</h2>
          <br></br>
          <h4>Voluntary Act</h4>
          <br></br>
          <p>
            The act of providing your Aadhaar is voluntary in nature, which will
            only be required for completing your account opening procedure
            online and for digitally signing the Shareway Securities Private
            Limited account opening form. Your Aadhaar may also be used as a
            proof of address as an alternative choice to driver’s license(DL) or
            Passport copy, which is a voluntary decision to be made by You.
            <br />
            You may alternatively choose to go through the offline route of
            account opening by physically signing all such account opening
            documents, which does not require you to share your Aadhaar
            information at all.
          </p>
          <br></br>

          <h4>
            Shareway Securities Private Limited does not store any Aadhaar
            information
          </h4>
          <br></br>

          <p>
            During your online account opening process, you do not input any
            Aadhaar details on the Fabits platform. You would be issuing your
            Digital Aadhaar to Digi locker, from where Shareway Securities
            Limited (Shareway Securities Private Limited is registered as a
            Requester on the Digi locker platform) would receive information,
            based on your consent to allow Digi locker to share such information
            with Shareway Securities Private Limited (Formerly Known As Shareway
            Securities Limited), limited to: (1) The last four digits of your
            Aadhaar / VID (Virtual ID created on the UIDAI website), (2) Full
            Name, (3) Date of Birth, (4) Gender, (5) Address, and (6) Your
            Photo.
          </p>
          <br></br>
          <p>
            Digi locker is a part of the National e-Governance Division under
            the Ministry of Electronics & Information Technology (MeitY),
            Government of India. The Digital Aadhaar issued within Digi Locker
            is the same as the eAadhaar issued by UIDAI
            (https://eaadhaar.uidai.gov.in). Digi Locker has partnered with
            UIDAI to make it available automatically to its users when they link
            their Digi Locker account with Aadhaar. This act of issuing an
            eAadhaar within Digi locker and subsequently Digi locker sharing the
            information limited to the above points, leads to behaving as a
            physical KYC. Shareway Securities Private Limited only receives
            information limited to the above points for the purposes of
            completing KYC of clients online.
          </p>
          <br></br>

          <p>
            All clients may alternatively follow the offline route of account
            opening, which does not require sharing any Aadhaar related
            information.
          </p>
          <br></br>

          <p>
            During your online account opening process, you would also be
            sharing your Aadhaar with Digiotech Solutions Pvt. Ltd. for the
            purpose of digitally signing the Shareway Securities Private Limited
            account opening form. Digiotech Solutions Pvt. Ltd. does not share
            your Aadhaar with Shareway Securities Private Limited. After the
            account opening form has been digitally signed by you, a copy of the
            digitally signed form would be emailed to you and to Shareway
            Securities Limited for reference/audit purposes. Digiotech Solutions
            Pvt. Ltd. is a licensed e-Sign ASP (Application Services Provider)
            with CDAC, compliant with the guidelines laid down by the Controller
            of Certifying Authorities (CCA), India and UIDAI, to enable you to
            eSign the account opening forms online.
          </p>
          <br></br>

          <h2>Collection and use of your non-personal information</h2>
          <br></br>
          <p>
            Shareway Securities Private Limited may from time to time collect
            information, i.e., non-personal information which cannot be used to
            identify you personally, while You are using the website or mobile
            application. Shareway Securities Private Limited may from time to
            time collect information such as non-personal information such as IP
            (internet protocol) addresses, browser types, the name of your ISP
            (internet service provider), information about a third-party
            link/URL from where You arrive to our website/mobile application,
            which pages/URLs do You click on the Shareway Securities Private
            Limited website / mobile application, and the amount of time spent
            on each.
            <br />
            <br />
            Shareway Securities Private Limited uses Google Analytics, a web
            analytics service provided by Google, Inc. Google Analytics uses
            first-party cookies to report on user interactions with our
            web-based services. These cookies store non-personally identifiable
            information.
          </p>
          <br></br>
          <h3>Use of cookies</h3>
          <br></br>
          <p>
            Shareway Securities Private Limited may from time-to-time use
            cookies. Cookies are small data files that a website stores on Your
            computer. Shareway Securities Private Limited may use persistent
            cookies which are permanently placed on your computer to store
            non-personal (Browser, ISP, OS, Clickstream information etc) and
            profiling information (age, gender, income etc). While cookies have
            unique identification no’s, personal information (name, a/c no,
            contact nos etc) shall not be stored on the cookies. We will use the
            information stored in the cookies to improve Your browsing
            experience on our website, by throwing up relevant content where
            possible. We will also use the cookies to store visitor preferences
            to ease visitor navigation on the site.
          </p>
          <br></br>

          <h2>Disclosure and transfer of collected information</h2>
          <br></br>
          <p>
            Shareway Securities Private Limited does not share any of your
            information with third parties except: (i) with respect to providing
            the information or services that you have requested earlier, (ii) or
            with your express permission for sharing such information, or (iii)
            with intermediaries/applications who require such information as per
            regulatory requirements and procedure laid down by SEBI/Exchanges.
            <br />
            <br />
            Under no circumstances will we sell or rent your personal
            information to anyone, for any reason, at any time. Shareway
            Securities Limited has taken best and reasonable steps to ensure
            prevention of any unauthorised disclosure of your sensitive personal
            information.
            <br />
            <br />
            Disclosure for regulatory compliances: Shareway Securities Private
            Limited will share your information with judicial, administrative,
            and regulatory entities to comply with any legal and regulatory
            requirements.
            <br />
            <br />
            Disclosures with your prior consent: If, at any time, you choose to
            utilise the integrated services of third-party service partners
            through us, we will share your information with these third party
            service partners with your prior consent.
            <br />
            <br />
            Disclosure for provision of services: Shareway Securities Private
            Limited will share your information with third party vendors of
            Shareway Securities Private Limited (Formerly Known As Shareway
            Securities Limited) as necessary for the provision of services.
            Authorized third party vendors are bound by the same standards of
            data security practices and procedures as we are under law and
            contract. They are subject to the same penalties as we are for the
            unauthorised disclosure of your personal information.
          </p>
          <br></br>
          <h2>Privacy Statement as per EU-GDPR</h2>
          <br></br>
          <p>
            Shareway Securities Private Limited shall implement and fulfil all
            its obligations under the EU General Data Protection Regulation
            2016/679 (“GDPR”). Shareway Securities Private Limited shall process
            any personal data we collect from you in accordance with Data
            Protection Legislation and the provisions of this Policy.
            <br />
            In case You are subjected to the above stated Regulation, you would
            have the below stated rights:
          </p>
          <br></br>
          <ul>
            <li>You may request your data be deleted or corrected.</li>
            <br></br>
            <li>
              You may request for access of any data that has been stored by
              Shareway Securities Private Limited for providing You services.
            </li>
            <br></br>
            <li>
              You may request storable & readable data to be transferred to
              another party, this shall be done by Shareway Securities Private
              Limited to the maximum extent possible.
            </li>
            <br></br>
            <li>
              As and where any data is requested, it would be taken only post
              your prior consent.
            </li>
            <br></br>
          </ul>
          <p>
            We are permitted to process your personal data in compliance with
            Data Protection Legislation by relying on one or more of the
            following lawful grounds:
          </p>
          <br></br>

          <ul>
            <li>
              The processing is necessary for the performance of a contract with
              you.
            </li>
            <br></br>
            <li>
              You have explicitly agreed to us processing such information for a
              specific reason.
            </li>
            <br></br>
            <li>
              The processing is necessary to perform the agreement we have with
              you or to take steps to enter into an agreement with you.
            </li>
            <br></br>
            <li>
              The processing is necessary for compliance with a legal obligation
              we have.
            </li>
            <br></br>
            <li>
              The processing is necessary for the purposes of a legitimate
              interest pursued by us, which might be to provide services to you;
              to ensure that Your trading & demat account products/services are
              running smoothly.
            </li>
            <br></br>
            <li>
              To prevent, detect, investigate, and prosecute fraud and alleged
              fraud, money laundering and other crimes and to verify your
              identity in order to protect our business and to comply with laws
              that apply to us and/or where such processing is a contractual
              requirement of the services you have requested.
            </li>
            <br></br>
            <li>to ensure that complaints are investigated.</li>
            <br></br>
            <li>to evaluate, develop or improve our services; or</li>
            <br></br>
            <li>
              to keep our customers informed about relevant services unless you
              have indicated at any time that you do not wish us to do so.
            </li>
            <br></br>
          </ul>
          <br></br>
          <p>
            You may refuse to accept cookies by activating the setting on your
            browser which allows you to refuse the setting of cookies. However,
            if you select this setting, you may be unable to access certain
            parts of our website. Unless you have adjusted your browser setting
            so that it will refuse cookies, our system will issue cookies when
            you log on to our site.
            <br />
            After giving a written notice, a user, who is subject to GDPR, shall
            have the right to receive Personal Information and Non-Personal
            Information, which is provided to Shareway Securities Private
            Limited during the usage of Shareway Securities Private Limited’s
            services, such that, this information may be used by another entity
            which is technically feasible by all parties to the maximum extent
            possible. For sending across any notice pertaining to removal or
            requesting to receive all such Personal Information and Non Personal
            Information, such that, this information may be transferred to
            another entity/controller of such data as per the GDPR, all subject
            users are required to write to “support@fabits.com”.
          </p>
          <br></br>
          <h2>Security</h2>
          <br></br>
          <p>
            Our hosting services and servers maintain its systems in accordance
            with industry standards and best practices to reasonably secure the
            information of its customers, such as using SSL encryption in
            certain places to prevent eavesdropping and employing up-to-date
            software on the server. However, no data transmission over the
            Internet can be guaranteed to be 100% secure. “Perfect security”
            does not exist on the Internet, and therefore You use the website
            and mobile application at your own risk.
            <br />
            Your data is transmitted between your device and our servers using
            HTTPS protocol for encryption. HTTPS is the technology used to
            create secure connections for your web browser and is indicated by a
            padlock icon in your browser.
            <br />
            Shareway Securities Private Limited follows industry best practices,
            using open and known principles when transferring and storing your
            data. We believe the biggest threat to the security and privacy of
            your data is if someone gains access to any of your devices.
            Remember to keep your password safe and secret to prevent
            unauthorised access to your Shareway Securities Private Limited
            account. If you think that the security of your Shareway Securities
            Private Limited account has been compromised, change your password,
            and Support immediately for further assistance.
          </p>
          <br></br>

          <h2>Correction/Updating or Access to Personal Information</h2>
          <br></br>
          <p>
            In case of any changes with respect to any of your information,
            especially Personal Information has been changed or in case you
            would not like to continue the services of Shareway Securities
            Limited, then you would be required to either update your Personal
            Information or unsubscribe from any updates/information with respect
            to notifications on the products/services that you had earlier
            utilised or enquired upon at Shareway Securities Private Limited. In
            case you would like to modify/delete any details with respect to
            your Personal / Non-personal information, or to access your
            information in a readable format, you would have to write to: (i)
            “india[@]Shareway Securities Private Limited[.]com”; and (ii)
            “legal[@]Shareway Securities Private Limited[.]com” or to the
            address: Shareway Securities Private Limited, at #Flat E,2nd
            Floor“Gilli Flower” No 6/46,23rd Street L Block Anna Nagar East
            Chennai-102
          </p>
          <br></br>

          <h2>Subject to Change</h2>
          <br></br>
          <p>
            This Privacy Policy is subject to change and may be updated or
            revised from time to time, without any prior notification to the
            User, at the sole discretion of Shareway Securities Private Limited.
            It is the responsibility of the User to periodically review the
            Privacy Policy to determine whether there have been any revisions or
            updates. The last updated date of the Policy has been mentioned
            above for the reference of the User.
            <br />
            If you do not agree with the terms of this Privacy Policy or any
            changes made to this Policy, please stop using all products and
            services provided by Shareway Securities Private Limited immediately
            and write to us at the email ID stated above.
            <br />
            <p className="copyrightssection">
              &copy; 2010 – {currentYear}, Shareway Securities Private Limited
              Broking Ltd. All rights reserved.
            </p>
          </p>
          <br></br>
          <p className="text-start px-2 content__paragraph2">
            <a href="https://evoting.cdslindia.com/Evoting/EVotingLogin">
              E-voting facility for Shareholders
            </a>
          </p>
          <br></br>
        </div>
      </div>
    </>
  );
}

export default PrivacyPolicy;
