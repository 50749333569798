import React, { useState } from "react";
import styles from "./Features.module.css";
import logo from "../../../assets/Gold_ETF_Page/Features/logo.svg";
import Fabits_Logo from "../../../assets/Gold_ETF_Page/Features/Fabits-Logo.svg"


const Features = () => {
    const [investmentType, setInvestmentType] = useState(0); // 0 = Sovereign Gold Bonds, 1 = EPF, 2 = Physical Gold

    const handleNext = () => setInvestmentType((investmentType + 1) % 3);
    const handlePrevious = () => setInvestmentType((investmentType + 2) % 3);

    return (
        <>
            <div className={styles.featureContent}>
                <div className={styles.container}>
                    <h1>
                        Here’s how your options compare
                    </h1>
                </div>

                <div className={styles.featuresContent}>
                    <div>
                        <h1>Features</h1>

                        <ul>
                            <li>Liquidity</li>
                            <span>(The higher the better)</span>
                            <li>Use</li>
                            <li>Safety</li>
                            <li>Min. Investment</li>
                            <li>GST</li>
                        </ul>
                    </div>

                    <div>
                        <div>
                            <img src={Fabits_Logo} alt="Fabits_Logo" />

                            <div>
                                <p>Fabits Gold Growth Fund</p>
                            </div>
                        </div>

                        <ul>
                            <li>High</li>
                            <li>Wealth Creation</li>
                            <li>High</li>
                            <li>Low (₹12)</li>
                            <li>None</li>
                        </ul>
                    </div>

                    <div></div>

                    <div>
                        <h1>Sovereign Gold Bonds</h1>

                        <ul>
                            <li>Very Low</li>
                            <li>Future Jewellery Needs</li>
                            <li>High</li>
                            <li>High (1g Gold - ₹7-8K)</li>
                            <li>None</li>

                        </ul>
                    </div>

                    <div>
                        <h1>Digital Gold</h1>

                        <ul>
                            <li>High</li>
                            <li>Investment</li>
                            <li>High</li>
                            <li>Low (₹1)</li>
                            <li>Yes (3% G.S.T.)</li>

                        </ul>
                    </div>

                    <div>
                        <h1>Physical Gold</h1>

                        <ul>
                            <li>Low</li>
                            <li>Jewellery</li>
                            <li>Low</li>
                            <li>High (1g Gold ₹7-8k)</li>
                            <li>Yes (3% G.S.T.)</li>

                        </ul>
                    </div>
                </div>

                <div className={styles.featuresContent_tab}>
                    <div>
                        <div className={styles.heading}>
                            <img src={Fabits_Logo} alt="Fabits_Logo" />

                            <div>
                                <p>Fabits Gold Growth Fund</p>
                            </div>
                        </div>
                        <div className={styles.feature}>
                            <div>
                                <div>
                                    <div>
                                        <span class="material-symbols-rounded">water_drop</span>
                                    </div>
                                    <div>
                                        <p>Liquidity</p>
                                        <p>High</p>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div>
                                    <div>
                                        <span class="material-symbols-rounded">timeline</span>
                                    </div>
                                    <div>
                                        <p>Use</p>
                                        <p>Wealth Creation</p>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div>
                                    <div>
                                        <span class="material-symbols-rounded">
                                            local_fire_department
                                        </span>
                                    </div>
                                    <div>
                                        <p>Safety</p>
                                        <p>High</p>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div>
                                    <div>
                                        <span class="material-symbols-rounded">
                                            assured_workload
                                        </span>
                                    </div>
                                    <div>
                                        <p>Min. Investment</p>
                                        <p>Low (₹12)</p>
                                    </div>
                                </div>
                                {/* <p>Capital gains tax</p> */}
                            </div>
                            <div>
                                <div>
                                    <div>
                                        <span class="material-symbols-rounded">
                                            volunteer_activism
                                        </span>
                                    </div>
                                    <div>
                                        <p>GST</p>
                                        <p>None</p>
                                    </div>
                                </div>
                            </div>
                            <div>
                                {/* <p>Via stock market</p> */}
                            </div>
                        </div>
                    </div>

                    <div>
                        <div>
                            <div>Sovereign Gold Bonds</div>

                            <div className={styles.featureEPF}>
                                <div>
                                    <div>
                                        <p>Moderate</p>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.featureEPF}>
                                <div>
                                    <div>
                                        <p>Future Jewellery Needs</p>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.featureEPF}>
                                <div>
                                    <div>
                                        <p>High</p>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.featureEPF}>
                                <div>
                                    <div>
                                        <p>High (1g Gold - ₹7-8K)</p>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.featureEPF}>
                                <div>
                                    <div>
                                        <p>None</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div>
                            <div>Digital Gold</div>

                            <div className={styles.featureEPF}>
                                <div>
                                    <div>
                                        <p>Moderate</p>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.featureEPF}>
                                <div>
                                    <div>
                                        <p>Investment</p>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.featureEPF}>
                                <div>
                                    <div>
                                        <p>High</p>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.featureEPF}>
                                <div>
                                    <div>
                                        <p>Low (₹1)</p>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.featureEPF}>
                                <div>
                                    <div>
                                        <p>Yes (3% G.S.T.)</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div>
                            <div>Physical Gold</div>

                            <div className={styles.featureEPF}>
                                <div>
                                    <div>
                                        <p>Low</p>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.featureEPF}>
                                <div>
                                    <div>
                                        <p>Jewellery</p>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.featureEPF}>
                                <div>
                                    <div>
                                        <p>Low</p>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.featureEPF}>
                                <div>
                                    <div>
                                        <p>High (1g Gold ₹7-8k)</p>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.featureEPF}>
                                <div>
                                    <div>
                                        <p>Yes (3% G.S.T.)</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={styles.featuresContent_mobile}>
                    <div>
                        <div className={styles.heading}>
                            <img src={Fabits_Logo} alt="Fabits_Logo" />

                            <div>
                                <p>Fabits Gold Growth Fund</p>
                            </div>
                        </div>
                        <div className={styles.feature}>
                            <div>
                                <div>
                                    <div>
                                        <span class="material-symbols-rounded">water_drop</span>
                                    </div>
                                    <div>
                                        <p>Liquidity</p>
                                        <p>High</p>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div>
                                    <div>
                                        <span class="material-symbols-rounded">timeline</span>
                                    </div>
                                    <div>
                                        <p>Use</p>
                                        <p>Wealth Creation</p>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div>
                                    <div>
                                        <span class="material-symbols-rounded">
                                            local_fire_department
                                        </span>
                                    </div>
                                    <div>
                                        <p>Safety</p>
                                        <p>High</p>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div>
                                    <div>
                                        <span class="material-symbols-rounded">
                                            assured_workload
                                        </span>
                                    </div>
                                    <div>
                                        <p>Min. Investment</p>
                                        <p>Low (₹12)</p>
                                    </div>
                                </div>
                                {/* <p>Capital gains tax</p> */}
                            </div>
                            <div>
                                <div>
                                    <div>
                                        <span class="material-symbols-rounded">
                                            volunteer_activism
                                        </span>
                                    </div>
                                    <div>
                                        <p>Low (₹12)</p>
                                        <p>None</p>
                                    </div>
                                </div>
                            </div>
                            <div>
                                {/* <p>Via stock market</p> */}
                            </div>
                        </div>
                    </div>

                    <div>
                        {investmentType === 0 && (
                            <div>
                                <div>Sovereign Gold Bonds</div>
                                <div className={styles.featureEPF}><p>Moderate</p></div>
                                <div className={styles.featureEPF}><p>Future Jewellery Needs</p></div>
                                <div className={styles.featureEPF}><p>High</p></div>
                                <div className={styles.featureEPF}><p>High (1g Gold - ₹7-8K)</p></div>
                                <div className={styles.featureEPF}><p>No</p></div>

                                <div className={`${styles.nextCardBtn} ${styles.left}`} onClick={handlePrevious}>
                                    <span className="material-symbols-rounded">arrow_back_ios</span>
                                </div>
                                <div className={`${styles.nextCardBtn} ${styles.right}`} onClick={handleNext}>
                                    <span className="material-symbols-rounded">arrow_forward_ios</span>
                                </div>
                            </div>
                        )}

                        {investmentType === 1 && (
                            <div>
                                <div>Digital Gold</div>
                                <div className={styles.featureEPF}><p>Moderate</p></div>
                                <div className={styles.featureEPF}><p>Investment</p></div>
                                <div className={styles.featureEPF}><p>High</p></div>
                                <div className={styles.featureEPF}><p>Low (₹1)</p></div>
                                <div className={styles.featureEPF}><p>Yes (3% G.S.T.)</p></div>

                                <div className={`${styles.nextCardBtn} ${styles.left}`} onClick={handlePrevious}>
                                    <span className="material-symbols-rounded">arrow_back_ios</span>
                                </div>
                                <div className={`${styles.nextCardBtn} ${styles.right}`} onClick={handleNext}>
                                    <span className="material-symbols-rounded">arrow_forward_ios</span>
                                </div>
                            </div>
                        )}

                        {investmentType === 2 && (
                            <div>
                                <div>Physical Gold</div>
                                <div className={styles.featureEPF}><p>Low</p></div>
                                <div className={styles.featureEPF}><p>Jewellery</p></div>
                                <div className={styles.featureEPF}><p>Low</p></div>
                                <div className={styles.featureEPF}><p>High (1g Gold ₹7-8k)</p></div>
                                <div className={styles.featureEPF}><p>Yes (3% G.S.T.)</p></div>

                                <div className={`${styles.nextCardBtn} ${styles.left}`} onClick={handlePrevious}>
                                    <span className="material-symbols-rounded">arrow_back_ios</span>
                                </div>
                                <div className={`${styles.nextCardBtn} ${styles.right}`} onClick={handleNext}>
                                    <span className="material-symbols-rounded">arrow_forward_ios</span>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default Features;
