import React from "react";
import styles from "./Financial.module.css";
import icon from "../../../../assets/Diwali_LandingPage/Financial/1.svg"
import icon1 from "../../../../assets/Diwali_LandingPage/Financial/2.svg"
import icon2 from "../../../../assets/Diwali_LandingPage/Financial/3.svg"
import icon3 from "../../../../assets/Diwali_LandingPage/Financial/4.svg"


const cards = [
    {
        id: 1,
        content: (
            <div className={styles.cardContent}>
                <img src={icon} alt="icon" className={styles.icon} />
                <p>Savings + Investments Review</p>
            </div>
        ),
    },
    {
        id: 2,
        content: (
            <div className={styles.cardContent}>
                <img src={icon1} alt="icon" className={styles.icon} />
                <p>Insurance Review</p>
            </div>
        ),
    },
    {
        id: 3,
        content: (
            <div className={styles.cardContent}>
                <img src={icon2} alt="icon" className={styles.icon} />
                <p>Tax Planning</p>
            </div>
        ),
    },
    {
        id: 4,
        content: (
            <div className={styles.cardContent}>
                <img src={icon3} alt="icon" className={styles.icon} />
                <p>Loan Evaluation</p>
            </div>
        ),
    },
];


const Financial = () => {
    return (
        <div className={styles.container}>
            <h1>What this Financial Review Includes</h1>
            <div className={styles.cardContainer}>
                {cards.map((card) => {
                    return (
                        <div key={card.id}>
                            <div> </div>
                            <div>{card.content}</div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default Financial;
