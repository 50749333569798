import React from "react";
import styles from "./FAQ.module.css";
import Accordian from "./Accordian/Accordion";

const faq = [
    {
        id: 1,
        question: "What is a financial wellness review?",
        answer: (
            <p>
                Our free financial wellness review is a comprehensive look at your
                financial health. We assess your savings, investments, insurance, tax
                planning, and any loans, offering personalized insights to help you make
                smarter financial decisions.
            </p>
        ),
    },
    {
        id: 2,
        question: "How long does the review take?",
        answer: (
            <p>
                The review typically takes about 30–45 minutes. It’s a quick, convenient
                way to gain a full picture of your finances and identify areas to
                improve your financial well-being.
            </p>
        ),
    },
    {
        id: 3,
        question: "Do I need to prepare anything before the session?",
        answer: (
            <>
                <p>
                    Ideally, yes! It helps if you have a basic overview of your savings,
                    investments, insurance policies, and any active loans. But don’t
                    worry—our team can guide you through it even if you don’t have all the
                    details on hand.
                </p>
            </>
        ),
    },
    {
        id: 4,
        question: "Is this review really free?",
        answer: (
            <p>
                Yes, it’s 100% free with no obligations. Our goal is to help you
                understand your current financial standing, and if you’re interested, we
                can discuss ways we can support you beyond this review. But there’s no
                pressure or commitment needed.
            </p>
        ),
    },
    {
        id: 5,
        question: "Who is this review for?",
        answer: (
            <p>
                This review is ideal for corporate professionals who want clarity on
                their financial health and guidance on saving, investing, and planning
                for the future. Whether you’re just starting out or mid-career, we’re
                here to help.
            </p>
        ),
    },
    {
        id: 6,
        question: "How is my data protected?",
        answer: (
            <p>
                Your privacy is our priority. We follow strict data protection
                protocols, ensuring that your information is handled securely and
                confidentially.
            </p>
        ),
    },
    {
        id: 7,
        question: "Can I bring a family member or partner to the session?",
        answer: (
            <p>
                Of course! If managing finances is a shared goal, we encourage you to
                include them. Financial wellness is often a team effort, and we’re here
                to help everyone get on the same page.
            </p>
        ),
    },
    {
        id: 8,
        question: "What happens after the review?",
        answer: (
            <p>
                After the review, you’ll walk away with a clear picture of your
                financial health and actionable insights. If you’d like further guidance
                or ongoing support, we’re happy to discuss additional services—but it’s
                entirely up to you.
            </p>
        ),
    },
];

const FAQ = () => {
    const handleDeviceRedirect = () => {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;

        if (/android/i.test(userAgent)) {
            // Redirect to Google Play Store for Android devices
            window.location.href = "https://play.google.com/store/apps/details?id=com.fabits.mywealth&hl=en_IN";
        } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            // Redirect to App Store for iOS devices
            window.location.href = "https://apps.apple.com/ca/app/fabits/id1600230367";
        } else {
            // Redirect to signup page for web users
            window.location.href = "https://mywealth.fabits.com/sign-up";
        }
    };

    return (
        <div className={styles.container}>
            <div className={styles.freecall}>
                <a href="#formSection">
                    <button className={styles.btn}>Get a free financial review</button>
                </a>
                <button className={styles.btn} onClick={handleDeviceRedirect}>
                    Check out our app
                </button>
            </div>
            <div className={styles.content}>
                <h1>Frequently Asked Questions</h1>
                <div className={styles.faqContainer}>
                    {faq.map((item) => (
                        <React.Fragment key={item.id}>
                            <Accordian title={item.question} desc={item.answer} />
                            <hr className={styles.ruler} />
                        </React.Fragment>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default FAQ;
