import React from "react";
import styles from "./FAQ.module.css";
import Accordian from "./Accordian/Accordian";

const faq = [
  {
    id: 1,
    question: "What is the National Pension System (NPS)?",
    answer: (
      <p>
        NPS is simply a flexible retirement tool built for Indians. It’s easily
        accessible, low cost, tax-efficient, portable, and supports voluntary
        defined-contribution. This is backed by the Government of India and
        regulated by the Pension Fund Regulatory and Development Authority
        (PFRDA).
      </p>
    ),
  },
  {
    id: 2,
    question: "What are the key features of NPS?",
    answer: (
      <ul>
        <li>
          Regulated - NPS is regulated by PFRDA, which is established through an
          Act of Parliament (PFRDA Act 2013)
        </li>
        <li>
          Pension for all - can be voluntarily subscribed by any Indian citizen
          (resident/non-resident/overseas citizen)
        </li>
        <li>
          Low cost – NPS is one of the low cost pension schemes in the world
        </li>
        <li>
          Flexible - Subscribers have various options to choose from - Point of
          Presence (PoP), Central Recordkeeping Agency (CRA), Pension Fund and
          Asset Allocation, etc. The choices exercised can be changed
          subsequently
        </li>
        <li>
          Portable – NPS Account can be transferred across employment,
          location(s)/geography(ies)
        </li>
        <li>
          Tax efficient – Tax incentives are available to the subscribers under
          the Income Tax Act 1961.
        </li>
        <li>
          Optimum returns – Market linked returns based on the investment choice
          made by the subscriber
        </li>
        <li>
          Transparent – Subscribers can access their NPS Accounts online 24X7
          and public disclosures are mandated.
        </li>
      </ul>
    ),
  },
  {
    id: 3,
    question: "What is the eligibility criteria for NPS?",
    answer: (
      <>
        <p>You’re eligible for NPS investment if you are -</p>
        <ul>
          <li>
            An Indian citizen (resident or non-resident) or overseas citizen of
            India (OCI)
          </li>
          <li>Aged between 18-70 years</li>
          <li>Compliant to “Know Your Customer” (KYC) norms.</li>
        </ul>
        <br />
        <p>
          Please note that Hindu Undivided Families (HUFs) and Persons of Indian
          Origin (PIO) are not eligible for subscribing to NPS.
        </p>
        <br />
        <p>
          Moreover, NPS is an Individual Pension Account and cannot be opened on
          behalf of a third person. The applicant should be legally competent to
          execute a contract as per the Indian Contract Act.
        </p>
      </>
    ),
  },
  {
    id: 4,
    question: "What is the all-citizen model?",
    answer: (
      <p>
        Citizens of India who are not financially dependent (self-employed,
        professionals, etc.) can open and contribute to his/her Individual
        Pension Account for creating a pension corpus from which regular income
        will be generated after retirement/working age.
      </p>
    ),
  },
  {
    id: 5,
    question: "What is a corporate model?",
    answer: (
      <p>
        This model is applicable for the employees working with corporates.
        Under this model, employees as well as their employers (on behalf of the
        employee), can contribute towards the NPS Account of the employee.
      </p>
    ),
  },
  {
    id: 6,
    question: "What is the difference between Tier I and Tier II NPS accounts?",
    answer: (
      <>
        <p>You can have two types of NPS accounts - Tier I & Tier II.</p>
        <p>
          The Tier I accounts are mandatory while the subscriber has the choice
          to opt for another Tier II account.
        </p>
        <br />
        <table className={styles.answer6Table}>
          <thead>
            <tr>
              <th>Tier 1</th>
              <th>Tier 2</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                Any Indian citizen between 18 and 65 is eligible to open it.
              </td>
              <td>Any Indian citizen who has an active Tier 1 account</td>
            </tr>
            <tr>
              <td>Minimum amount to start investing is ₹500</td>
              <td>Minimum amount to start investing is ₹1,000</td>
            </tr>
            <tr>
              <td>
                Tier 1 accounts have a lock in period until the investor turns
                60
              </td>
              <td>Tier 2 accounts don't have any lock-in period</td>
            </tr>
            <tr>
              <td>
                Section 8OC of the Income Tax Act permits deductions for
                contributions up to ₹1,50,000 annually. Section 8OCCD(10) allows
                for additional deductions of ₹9,000
              </td>
              <td>Tier 2 contributions are not exempt from taxes</td>
            </tr>
            <tr>
              <td>
                For the first three years, withdrawals are not permitted. After
                that, you can withdraw up to 25% of the fund's value, but only
                for certain spends. When you turn 60, you can make a one-time
                withdrawal of a maximum of 60% with a minimum of 40% going into
                annuities to provide you with a monthly pension.
              </td>
              <td>
                Withdrawals are 100% digital, and super flexible! You can invest
                or exit any time you want.
              </td>
            </tr>
            <tr>
              <td>
                Taxes are not applicable on 60% of the corpus, withdrawn at
                maturity.
              </td>
              <td>
                Withdrawn funds are added to your income and then taxed
                according to the bracket you fall under overall
              </td>
            </tr>
            <tr>
              <td>
                Not only can you transfer funds from your Tier 2, to Tier 1
                account, but also move your funds from EPF to your Tier 1
                account.
              </td>
              <td>
                Transferring funds is to a Tier 2 account is not permitted
              </td>
            </tr>
          </tbody>
        </table>
      </>
    ),
  },
  {
    id: 7,
    question: "What are the tax benefits in NPS?",
    answer: (
      <>
        <p>
          NPS contributions are eligible for tax deductions under Sec. 80 CCD
          (1) of the Income Tax Act, up to 10% of basic + DA or up to 20% of
          gross income for self-employed within the overall ceiling of ₹ 1.50
          lakh under Sec. 80 CCE.
        </p>
        <br />
        <p>
          An additional deduction of up to ₹ 50,000/- is available under Sec.
          80CCD 1(B) of the Income Tax Act.
        </p>
        <br />
        <p>
          In case you receive contributions from the employer as well, tax
          deduction under section 80 CCD (2) of the Income Tax Act may be
          claimed by you in addition to the tax benefits available under Sec. 80
          CCE, subject to an aggregate limit of ₹ 7.5 lakh of contributions made
          towards NPS, recognised provident fund and approved superannuation
          fund.
        </p>
      </>
    ),
  },
  {
    id: 8,
    question:
      "What are the different fund management schemes available to you?",
    answer: (
      <>
        <p>NPS offers two approaches to invest your money:</p>
        <br />
        <ul className={styles.diffUl}>
          <li>
            <span>Active choice –</span> Here you get to decide the asset
            classes in which your contribution should be invested in.
          </li>
          <li>
            <span> Auto choice -</span> This is the default option under NPS
            wherein the management of investment of funds is done automatically
            based on your age profile.
          </li>
        </ul>
      </>
    ),
  },
  {
    id: 9,
    question: "What is the minimum amount required for NPS contribution?",
    answer: (
      <div className={styles.answer9}>
        <p>Minimum contributions (for Tier-I) -</p>
        <ul>
          <li>
            Minimum contribution at the time of account opening and for
            subsequent transactions – ₹ 500/-
          </li>
          <li>
            Minimum contribution per year – ₹ 1,000/- excluding any charges and
            taxes
          </li>
          <li>
            If you contribute less than ₹ 1,000/- in a year, your account will
            be frozen and further transactions will be allowed only after the
            account is reactivated
          </li>
          <li>
            In order to reactivate your account, you will have to pay the
            minimum contribution amount.
          </li>
        </ul>

        <br />
        <p>Minimum contributions (for Tier-II) -</p>

        <ul>
          <li>Minimum contribution at the time of account opening – ₹ 100/-</li>
          <li>
            For subsequent transactions, a minimum contribution of ₹ 250/- per
            year is required
          </li>
          <li>
            Under NPS, the manner in which your money is invested will depend
            upon the your own choice. NPS offers a number of funds and multiple
            investment options to choose from. In case you do not want to or
            have the expertise to make this choice, your money will be invested
            as per the ‘Auto Choice’ option. In this, your money will be
            invested in accordance with your age.
          </li>
        </ul>
      </div>
    ),
  },
  {
    id: 10,
    question: "Where can I view my contribution towards NPS?",
    answer: (
      <>
        <p>
          If you open an account with Fabits on ICICI Bank, you will be given an
          NPS portfolio service where you can view the following details -
        </p>
        <ul>
          <li>Total amount invested till date</li>
          <li>Total holdings</li>
          <li>Notional gain/loss</li>
          <li>XIRR & FY-XIRR</li>
          <li>PFM & scheme details</li>
          <li>Asset allocation</li>
          <li>Last five transactions</li>
          <li>
            Download statements of the transactions for any financial year
          </li>
        </ul>
      </>
    ),
  },
  {
    id: 11,
    question: "Can I have a joint account for NPS?",
    answer: (
      <p>
        No, for NPS, only individuals are allowed to open accounts; no joint
        accounts
      </p>
    ),
  },
  {
    id: 12,
    question: "When can I withdraw my money from my NPS account?",
    answer: (
      <div className={styles.answer12}>
        <p>
          NPS is super flexible and allows you to withdraw your money at
          multiple points in time, without any hassle. You can withdraw your
          money from your NPS account under the following
          circumstances/conditions -
        </p>

        <ul>
          <li>
            <span>Normal withdrawal -</span> You can withdraw normally when you
            turn 60 (if you start before the age of 60) or after contributing
            for a minimum of 3 years (if you started NPS after 60 years of age).
            In the above cases you can withdraw a maximum of 60% of your entire
            corpus as lumpsum while leaving a minimum 40% to be paid to you as
            monthly pension. If the accumulated corpus is less than ₹5 lakh, the
            entire corpus can be withdrawn as lumpsum.
          </li>
          <li>
            <span>Partial withdrawal -</span> If you’ve been contributing to
            your NPS account for at least 3 years, you are eligible to withdraw
            up to 25% of your contributions for specific reasons like - illness,
            disability, education or marriage of children, purchasing property,
            starting a new venture, etc. You can make partial withdrawals for a
            maximum of 3 times during your entire tenure.
          </li>
          <li>
            <span>Premature withdrawal -</span> If you start contributing to
            your NPS account before 60, you can make a premature withdrawal
            after 5 years. In this case, you can withdraw a maximum lumpsum of
            20% and leave a minimum of 80% to be paid as monthly pension by
            investing in annuities. For some one who started contributing after
            the age of 60, the same is applicable before 3 years of contribution
            tenure. If the accumulated corpus is less than ₹2.5 lakh, you get
            the entire corpus paid as lumpsum.
          </li>
        </ul>
      </div>
    ),
  },
  {
    id: 13,
    question: "What if I don’t want to exit NPS at 60 years of age?",
    answer: (
      <p>
        If you do not wish to exit NPS when you turn 60, the account will
        automatically continue to operate till you turn 75. After turning 75,
        you need to mandatorily close your NPS account. However, between the age
        of 60-75, you can exercise your right to exit the NPS account normally
        at any point you wish.
      </p>
    ),
  },
];

const FAQ = () => {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <h1>Frequently Asked Questions</h1>

        <div className={styles.faqContainer}>
          {faq.map((item) => (
            <>
              <Accordian
                key={item.id}
                title={item.question}
                desc={item.answer}
              />
              <hr className={styles.ruler} />
            </>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FAQ;
