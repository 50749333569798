import React from "react";
import Modal from "react-modal";

Modal.setAppElement("#root");

const MyModal = ({ isOpen, onRequestClose, children }) => {
  const isMobile = window.innerWidth <= 767;
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={{
        position: "absolute",
        overlay: {
          backgroundColor: "rgba(0, 0, 0, 0.5)",
        },
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          zIndex: "999",
          transform: "translate(-50%, -50%)",
          padding: "20px",
          borderRadius: "8px",
          height: `${isMobile ? "70%" : ""}`,
        },
      }}
    >
      {children}
    </Modal>
  );
};

export default MyModal;
