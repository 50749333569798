import React, { useEffect } from "react";
import "./module.css";
import { Helmet } from "react-helmet";
import ReactGA4 from "react-ga4";
import { useLocation } from "react-router-dom";
import clevertap from "clevertap-web-sdk";

function About() {
  const location = useLocation();
  const photoSections = document.querySelectorAll(".photosections");

  photoSections.forEach((section) => {
    section.addEventListener("click", () => {
      section.classList.toggle("active");
    });
  });

  useEffect(() => {
    ReactGA4.send("pageview", location.pathname);
    clevertap.event.push("Page Viewed", {
      Page_name: "Home Page",
    });
  }, [location.pathname]);

  return (
    <>
      <div className="aboutustopsection">
        <Helmet>
          <title>Fabits - About us</title>
          <meta
            name="description"
            content="Backed by HNI wealth managers with 25+ years of experience"
          />
        </Helmet>

        <div className="leftpartoftopsection">
          <div className="aboutusheading">
            <h3 className="decideyourfuture">
              Financial habits decide your future
            </h3>
            <p className="fabitsstorey">
              Fabits was founded in 2022 to double down on this very thought
              <strong>
                {" "}
                - how can small financial habits help us shape the future we’ve
                always dreamt of?
              </strong>
              <br></br>
              <br></br>
              At Fabits, we believe in personalisation to the point where we
              become your personal (finance) assistant. From planning and
              managing your money, to actually achieving your financial goals -
              we’re designed to be there with you each step of the way.{" "}
              <br></br>
              <br></br>
              We’re backed by Jayaprakash Pai, Founder, WMU India, who brings
              25+ years of experience in managing money for the ultrawealthy,
              straight to you, on your phone.
            </p>
          </div>
          <a href="#knowourteamsection">
            <button className="meetourteam">Meet our Team</button>
          </a>
        </div>
        <div className="rightpartoftopsection">
          <div className="righttposection">
            <img
              src="/Aboutus/Frame 455.jpg"
              alt="Frame455"
              id="frame455"
            ></img>
          </div>
          <div className="rightbottemsection">
            <div className="experenceandplaystore">
              <div className="datainsideboxes" id="experenceheadr">
                <h4 className="experence">50+ years</h4>
                <p className="experencepara">Experience</p>
              </div>
              <div className="datainsideboxes" id="ratingheater">
                <h4 className="experence">4.5 stars</h4>
                <p className="experencepara">Google Play Store</p>
              </div>
            </div>
            <div className="satisfiedusersandgains">
              <div className="datainsideboxes" id="satisifyedusers">
                <h4 className="experence">10K+</h4>
                <p className="experencepara">Satisfied Users</p>
              </div>
              <div className="datainsideboxes">
                <h4 className="experence">+24%</h4>
                <p className="experencepara">Avg. Gains (FY 23-24)*</p>
              </div>
            </div>
          </div>
          <p id="etfbaseddiversi">*ETF-based diversified portfolios</p>
        </div>
      </div>
      <div className="aboutussecondsection">
        <h4 className="ourpurpose">Our Purpose</h4>
        <p className="visionandmission">
          <strong>Vision:</strong> To help India reach financial independence.
        </p>

        <p className="visionandmission" id="mission">
          <strong>Mission:</strong> To make financial planning and money
          management accessible to all.
        </p>
      </div>
      <div className="knowourteamsection" id="knowourteamsection">
        <h3 className="knowourteamtext">Know our team</h3>
        <p className="knowourteambelowtextpara">
          Here’s who work hard to make your financial life simpler
        </p>
        <div className="aboutuspagephotssection">
          {/* The photo Section */}
          <div className="photosections">
            <img
              src="Aboutus/jay.png"
              alt="Jay"
              className="imagesonaboutuspage"
            ></img>
            <div className="linkdinarea">
              <div className="Bio">
                <h3 className="nameofperson">Jay</h3>
                <p className="designationofperson">Founder-Advisor</p>
              </div>
            </div>
          </div>
          <div className="photosections">
            <img
              src="Aboutus/sunder.png"
              alt="Sunder"
              className="imagesonaboutuspage"
            ></img>
            <div className="linkdinarea">
              <div className="Bio">
                <h3 className="nameofperson">Sunder</h3>
                <p className="designationofperson">Tech Advisor</p>
              </div>
            </div>
          </div>
          
          <div className="photosections">
            <img
              src="Aboutus/Kavitha.png"
              alt="Kavitha"
              className="imagesonaboutuspage"
            ></img>
            <div className="linkdinarea">
              <div className="Bio">
                <h3 className="nameofperson">Kavitha</h3>
                <p className="designationofperson">Founder-Advisor</p>
              </div>
            </div>
          </div>
          <div className="photosections">
            <img
              src="Aboutus/shreyas.png"
              alt="Shreyas"
              className="imagesonaboutuspage"
            ></img>
            <div className="linkdinarea">
              <a
                href="https://www.linkedin.com/in/shreyaskabirdass/"
                className="linkdinlink"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="Bio">
                  <h3 className="nameofperson">Shreyas</h3>
                  <p className="designationofperson">Co-Founder and Advisor</p>
                </div>
                <img
                  src="Aboutus/Linkdin.svg"
                  alt="linkedin"
                  className="linkedinicon"
                ></img>
              </a>
            </div>
          </div>
          <div className="photosections">
            <img
              src="Aboutus/Ajath2.png"
              alt="Ajath"
              className="imagesonaboutuspage"
            ></img>
            <div className="linkdinarea">
              <a
                href="https://www.linkedin.com/in/ajathanjanappa/"
                className="linkdinlink"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="Bio">
                  <h3 className="nameofperson">Ajath</h3>
                  <p className="designationofperson">Chief Executive Officer</p>
                </div>
                <img
                  src="Aboutus/Linkdin.svg"
                  alt="linkedin"
                  className="linkedinicon"
                ></img>
              </a>
            </div>
          </div>
          <div className="photosections">
            <img
              src="Aboutus/Adheip.png"
              alt="Adheip"
              className="imagesonaboutuspage"
            ></img>
            <div className="linkdinarea">
              <a
                href="https://www.linkedin.com/in/adheippai/"
                className="linkdinlink"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="Bio">
                  <h3 className="nameofperson">Adheip</h3>
                  <p className="designationofperson">Chief Marketing Officer</p>
                </div>
                <img
                  src="Aboutus/Linkdin.svg"
                  alt="linkedin"
                  className="linkedinicon"
                ></img>
              </a>
            </div>
          </div>
          <div className="photosections">
            <img
              src="Aboutus/dharma.png"
              alt="Dharma"
              className="imagesonaboutuspage"
            ></img>
            <div className="linkdinarea">
              <a
                href="https://www.linkedin.com/in/dharmaraj-hari-251284227/"
                className="linkdinlink"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="Bio">
                  <h3 className="nameofperson">Dharma</h3>
                  <p className="designationofperson">Advisor</p>
                </div>
                <img
                  src="Aboutus/Linkdin.svg"
                  alt="linkedin"
                  className="linkedinicon"
                ></img>
              </a>
            </div>
          </div>
          {/* 2 */}
          <div className="photosections">
            <img
              src="Aboutus/Ramesh.png"
              alt="Ramesh"
              className="imagesonaboutuspage"
            ></img>
            <div className="linkdinarea">
              <a
                href="https://www.linkedin.com/in/ramesh-logidasan-69550523/"
                className="linkdinlink"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="Bio">
                  <h3 className="nameofperson">Ramesh</h3>
                  <p className="designationofperson">Operations</p>
                </div>
                <img
                  src="Aboutus/Linkdin.svg"
                  alt="linkedin"
                  className="linkedinicon"
                ></img>
              </a>
            </div>
          </div>
          {/* 3 */}
          <div className="photosections">
            <img
              src="Aboutus/02-removebg-preview.png"
              alt="Rathi"
              className="imagesonaboutuspage"
            ></img>
            <div className="linkdinarea">
              <div className="Bio">
                <h3 className="nameofperson">Rathi</h3>
                <p className="designationofperson">Compliance & Operations</p>
              </div>
            </div>
          </div>
          {/* 4 */}
          <div className="photosections">
            <img
              src="Aboutus/Prajwal.png"
              alt="Prajwal"
              className="imagesonaboutuspage"
            ></img>
            <div className="linkdinarea">
              <a
                href="https://www.linkedin.com/in/prajwal-d-k-066401239/"
                className="linkdinlink"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="Bio">
                  <h3 className="nameofperson">Prajwal</h3>
                  <p className="designationofperson">Backoffice Operations</p>
                </div>
                <img
                  src="Aboutus/Linkdin.svg"
                  alt="linkedin"
                  className="linkedinicon"
                ></img>
              </a>
            </div>
          </div>
          <div className="photosections">
            <img
              src="Aboutus/jeeva.png"
              alt="Jeeva"
              className="imagesonaboutuspage"
            ></img>
            <div className="linkdinarea">
              <div className="Bio">
                <h3 className="nameofperson">Jeeva</h3>
                <p className="designationofperson">Customer Success</p>
              </div>
            </div>
          </div>
          <div className="photosections">
            <img
              src="Aboutus/DEB.png"
              alt="Deblina"
              className="imagesonaboutuspage"
            ></img>
            <div className="linkdinarea">
              <a
                href="https://www.linkedin.com/in/deblina-chakraborty-029/"
                className="linkdinlink"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="Bio">
                  <h3 className="nameofperson">Deblina</h3>
                  <p className="designationofperson">Marketing</p>
                </div>
                <img
                  src="Aboutus/Linkdin.svg"
                  alt="linkedin"
                  className="linkedinicon"
                ></img>
              </a>
            </div>
          </div>
          {/* 5 */}
          {/* 6 */}
          <div className="photosections">
            <img
              src="Aboutus/vishal.png"
              alt="Vishal"
              className="imagesonaboutuspage"
            ></img>
            <div className="linkdinarea">
              <a
                href="https://www.linkedin.com/in/vishal-viswanath-25926321b/"
                className="linkdinlink"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="Bio">
                  <h3 className="nameofperson">Vishal</h3>
                  <p className="designationofperson">Marketing</p>
                </div>
                <img
                  src="Aboutus/Linkdin.svg"
                  alt="linkedin"
                  className="linkedinicon"
                ></img>
              </a>
            </div>
          </div>
          <div className="photosections">
            <img
              src="Aboutus/ashar.png"
              alt="Ashar"
              className="imagesonaboutuspage"
            ></img>
            <div className="linkdinarea">
              <a
                href="https://www.linkedin.com/in/0xtr0jan/"
                className="linkdinlink"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="Bio">
                  <h3 className="nameofperson">Ashar</h3>
                  <p className="designationofperson">Product Manager</p>
                </div>
                <img
                  src="Aboutus/Linkdin.svg"
                  alt="linkedin"
                  className="linkedinicon"
                ></img>
              </a>
            </div>
          </div>
          <div className="photosections">
            <img
              src="Aboutus/dhairya.png"
              alt="Dhairyadev"
              className="imagesonaboutuspage"
            ></img>
            <div className="linkdinarea">
              <a
                href="https://www.linkedin.com/in/dhairyadev/"
                className="linkdinlink"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="Bio">
                  <h3 className="nameofperson">Dhairyadev</h3>
                  <p className="designationofperson">Product Designer</p>
                </div>
                <img
                  src="Aboutus/Linkdin.svg"
                  alt="linkedin"
                  className="linkedinicon"
                ></img>
              </a>
            </div>
          </div>
          <div className="photosections">
            <img
              src="Aboutus/Shanti.png"
              alt="Shantilal"
              className="imagesonaboutuspage"
            ></img>
            <div className="linkdinarea">
              <a
                href="https://www.linkedin.com/in/spsanchore13/"
                className="linkdinlink"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="Bio">
                  <h3 className="nameofperson">Shantilal</h3>
                  <p className="designationofperson">Software Engineer</p>
                </div>
                <img
                  src="Aboutus/Linkdin.svg"
                  alt="linkedin"
                  className="linkedinicon"
                ></img>
              </a>
            </div>
          </div>
          <div className="photosections">
            <img
              src="Aboutus/Raghavendra.png"
              alt="Raghavendra"
              className="imagesonaboutuspage"
            ></img>
            <div className="linkdinarea">
              <a
                href="https://www.linkedin.com/in/raghavendra-haiyalkar-46729ab6/"
                className="linkdinlink"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="Bio">
                  <h3 className="nameofperson">Raghavendra</h3>
                  <p className="designationofperson">Senior Manager, Business</p>
                </div>
                <img
                  src="Aboutus/Linkdin.svg"
                  alt="linkedin"
                  className="linkedinicon"
                ></img>
              </a>
            </div>
          </div>
          <div className="photosections">
            <img
              src="Aboutus/Ruchi.png"
              alt="Ruchi"
              className="imagesonaboutuspage"
            ></img>
            <div className="linkdinarea">
              <a
                href="https://www.linkedin.com/in/ruchi-agrawal-613b53286/"
                className="linkdinlink"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="Bio">
                  <h3 className="nameofperson">Ruchi</h3>
                  <p className="designationofperson">Marketing Associate</p>
                </div>
                <img
                  src="Aboutus/Linkdin.svg"
                  alt="linkedin"
                  className="linkedinicon"
                ></img>
              </a>
            </div>
          </div>
          {/* 7 */}
          {/* 8 */}
          {/* 9 */}

          {/* All the photos are same, just for the sake of design */}
         
          
        </div>
      </div>
    </>
  );
}

export default About;

