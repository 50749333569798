import React from "react";
import styles from "./card.module.css";

const Card = ({ id, user, age, role, image, content }) => {
  return (
    <div className={styles.container}>
      <div key={id} className={styles.cardContainer}>
        <div className={styles.card}>
          <div className={styles.front}>
            <div className={styles.Image}>
              <img src={image} alt="Images" />
            </div>
            <div className={styles.overlay}>
              <div>
                <div>
                  <h1>{user}</h1>
                  <h1>{age}</h1>
                </div>
                <p>{role}</p>
              </div>
            </div>
          </div>
          <div className={styles.back}>
            <span>{content}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;
