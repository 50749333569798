import { useLocation } from "react-router-dom";
import React, { useState } from "react";
import Logo from "../assets/common/logo.png";
import X from "../assets/common/x.svg";
import LinkedIn from "../assets/common/linkedin.svg";
import Youtube from "../assets/common/youtube.svg";
import Instagram from "../assets/common/instagram.svg";
import "./Footer.css";
import clevertap from "clevertap-web-sdk";
import useMediaQuery from "../hooks/useMediaQuery";

const footerContents = {
  name: "",
  icon: Logo,
  favIcons: [
    {
      url: X,
      link: "https://twitter.com/fabitssocial?utm_source=socials&utm_medium=website_footer&utm_id=Socials+Website",
    },
    {
      url: LinkedIn,
      link: "https://www.linkedin.com/company/79926119/admin/feed/posts/?utm_source=socials&utm_medium=website_footer&utm_id=Socials+Website",
    },
    {
      url: Youtube,
      link: "https://www.youtube.com/@fabits.social?utm_source=socials&utm_medium=website_footer&utm_id=Socials+Website",
    },
    {
      url: Instagram,
      link: "https://www.instagram.com/fabits.social/?utm_source=socials&utm_medium=website_footer&utm_id=Socials+Website",
    },
  ],
  address:
    "Fabits (Shareway Securities Private Ltd.) | 294/1, 1st Floor, 7th Cross Rd, | Domlur 1st Stage, | Bengaluru, Karnataka - 560071",
  info: "SEBI Reg. No.: INZ000208134 | AMFI Registration Number : ARN-310082 | Segments: NSE CM - FO | CDSL Depository Participant: IN-DP-610-2021 | GST NO: 29AALCS7597J1ZA",
  navLinks: [
    {
      title: "Company",
      links: [
        {
          label: "About",
          url: "/about",
        },
        {
          label: "Blog",
          url: "http://blog.fabits.com/",
        },
        {
          label: "Support",
          url: "/contactus",
        },

        // {
        //   label: "Investor Complaints",
        //   url: "/investorcomplaints",
        // },
      ],
    },
    // {
    //   title: "Compliance",
    //   links: [
    //     {
    //       label: "Privacy",
    //       url: "/PrivacyPolicy",
    //     },
    //     {
    //       label: "Disclosures",
    //       url: "/disclosure",
    //     },
    //     {
    //       label: "Policies & Procedures",
    //       url: "/PoliciesAndProcedures",
    //     },
    //     {
    //       label: "Terms",
    //       url: "/Termsandconditions",
    //     },

    //     {
    //       label: "Bank Details",
    //       url: "/FabitsAssets/Bank-Details.pdf",
    //     },
    //   ],
    // },
    {
      title: "Investors Point",
      links: [
        {
          label: "Investor's Portal",
          url: "/InvestorCharter",
        },
        {
          label: "Investor's Awareness",
          url: "/InvestorAwareness",
        },
        {
          label: "Back Office",
          url: "https://backoffice.fabits.app/shrdbms/userlogin.ss",
        },
        {
          label: "Check Your Collateral Details",
          url: "https://investorhelpline.nseindia.com/ClientCollateral/welcomeCLUser",
        },
      ],
    },
    {
      title: "Get Started",
      links: [
        {
          label: "What is Fabits?",
          url: "https://youtu.be/1GATUfsgyN0?si=a-ZvBS56Ri4ShiCM",
        },
        {
          label: "How to open an account",
          url: "https://wa.me/+918217801975?text=Hi+I+want+to+open+an+account+on+Fabits+and+plan+my+finances+more+efficiently.+How+can+I+get+started?",
        },
        {
          label: "Plan a vacation",
          url: "https://blog.fabits.com/2023/04/24/vacation-planning-budget-your-trip-with-us/",
        },
        {
          label: "Plan your retirement",
          url: "https://blog.fabits.com/2023/05/08/retirement-planning-its-never-too-late-for-a-retirement-plan/",
        },
        {
          label: "Calculators",
          url: "/calculator",
        },
      ],
    },
  ],
};

const Footer = () => {
  const location = useLocation();
  const isDesktop = useMediaQuery("(min-width: 769px)");
  const currentYear = new Date().getFullYear();

  const handleClick = (url, label) => {
    clevertap.event.push("Clicked Button", {
      Action: `Clicked ${label} Link`,
      Page_name: location.pathname,
    });
    window.open(url, "_blank");
  };
  const [activeAccordion, setActiveAccordion] = useState(null);

  const toggleAccordion = (index) => {
    setActiveAccordion(activeAccordion === index ? null : index);
  };

  return (
    <div className="footer-container">
      <div className="footer-header">
        <div className="footer-item">
          {/* <img className="footer-logo" src={footerContents.icon} alt="imageop" /> */}
          {/* <h2>{footerContents.name}</h2> */}
          {footerContents.address.split("|").map((each) => (
            <ul key={each}>{each}</ul>
          ))}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "20px",
              marginTop: "20px",
              padding: "8px",
            }}
          >
            {footerContents.favIcons.map((favIcon, index) => (
              <a
                key={index}
                href={favIcon.link}
                onClick={() => handleClick(favIcon.link, "Social Icon")}
              >
                <img
                  height={24}
                  src={favIcon.url}
                  alt={`Social Icon ${index + 1}`}
                />
              </a>
            ))}
          </div>
        </div>
        <div className="footer-item">
          {footerContents.info.split("|").map((eachItem, index) => (
            <ul key={index}>{eachItem}</ul>
          ))}
        </div>
      </div>
      <br />
      <br />
      <div className="nav-link-container">
        {footerContents.navLinks.map((navItem, index) => (
          <div key={index} className="accordion-item">
            <button
              className={`accordion_footer ${
                activeAccordion === index ? "footer_active" : ""
              }`}
              onClick={() => toggleAccordion(index)}
            >
              <b style={{ textDecoration: `${isDesktop ? "underline" : ""}` }}>
                {navItem.title}
              </b>
              {/* Conditional rendering of +/- signs */}
              <span className="accordion-sign">
                {activeAccordion === index ? "-" : "+"}
              </span>
            </button>
            <div
              className={`panel_footer ${
                activeAccordion === index ? "show" : ""
              }`}
            >
              {navItem.links.map((eachItem, linkIndex) => (
                <ul key={linkIndex}>
                  <a
                    href={eachItem.url}
                    target="_blank" // Ensures links open in a new tab
                    rel="noopener noreferrer"
                  >
                    {eachItem.label}
                  </a>
                </ul>
              ))}
            </div>
          </div>
        ))}
      </div>

      <br />
      <div className="dashed-line" />

      <article>
        SHAREWAY SECURITIES PRIVATE LIMITED (FORMERLY KNOWN AS SHAREWAY
        SECURITIES LIMITED) Member of NSE – SEBI Registration number:
        INZ000208134 CDSL: Depository services through SHAREWAY SECURITIES
        PRIVATE LIMITED – SEBI Registration number: IN-DP-610-2021. Registered
        Address: old no 46 new no 6, Gilli flower, flat, 2nd floor, 23rd street,
        Anna Nagar East, Chennai 600102. Corporate Address: 294/1, 7th Cross,
        Domlur Layout above Union Bank, Bangalore - 560071. For any complaints
        pertaining to securities broking please write to rathi@fabits.com .
        Please ensure you carefully read the Risk Disclosure Document as
        prescribed by SEBI <br></br> <br></br>Procedure to file a complaint on{" "}
        <a href="https://scores.sebi.gov.in/">SEBI SCORES 2.0</a> (
        <a
          href="https://play.google.com/store/apps/details?id=com.sebi&hl=en"
          target="_blank"
        >
          Android Application
        </a>
        ,&nbsp;
        <a
          href="https://apps.apple.com/in/app/sebiscores/id6478849917"
          target="_blank"
        >
          IOS Application
        </a>
        ) : Register on SCORES portal. Mandatory details for filing complaints
        on SCORES: Name, PAN, Address, Mobile Number, E-mail ID. Benefits:
        Effective Communication, Speedy redressal of the grievances. Investments
        in securities market are subject to market risks; read all the related
        documents carefully before investing. <br></br>Attention investors:{" "}
        <ol>
          {" "}
          1) Stock brokers can accept securities as margins from clients only by
          way of pledge in the depository system w.e.f September 01, 2020.{" "}
          <ol></ol> 2) Update your e-mail and phone number with your stock
          broker / depository participant and receive OTP directly from
          depository on your e-mail and/or mobile number to create pledge.
          <ol></ol> 3) Check your securities / MF / bonds in the consolidated
          account statement issued by NSDL/CDSL every month.{" "}
        </ol>{" "}
        <br></br>
        <div>
          <p
            style={{
              fontSize: "24px",
              textDecoration: "underline",
              paddingLeft: "0px",
              marginBottom: "20px",
            }}
          >
            Attention Investors -
          </p>
          <ol>
            <li>
              Prevent unauthorised transactions in your account. Update your
              mobile numbers/email IDs with your stock brokers. Receive
              information of your transactions directly from Exchange on your
              mobile/email at the end of the day. Issued in the interest of
              investors.
            </li>
            <li style={{ margin: "20px 0px" }}>
              KYC is one time exercise while dealing in securities markets -
              once KYC is done through a SEBI registered intermediary (broker,
              DP, Mutual Fund etc.), you need not undergo the same process again
              when you approach another intermediary.
            </li>
            <li style={{ margin: "0px 0px 20px 0px" }}>
              Dear Investor, if you are subscribing to an IPO, there is no need
              to issue a cheque. Please write the Bank account number and sign
              the IPO application form to authorize your bank to make payment in
              case of allotment. In case of non allotment the funds will remain
              in your bank account.
            </li>
          </ol>
        </div>
        As a business we don't give stock tips, and have not authorized anyone
        to trade on behalf of others. If you find anyone claiming to be part of
        Fabits and offering such services, please call us.
        <ul>
          <li style={{ marginBottom: "10px" }}>
            <a
              href="https://evoting.cdslindia.com/Evoting/EVotingLogin"
              target="_blank"
              rel="noreferrer"
            >
              E-voting facility for Shareholders
            </a>
          </li>
          <li>
            <a
              href="https://smartodr.in/login"
              target="_blank"
              rel="noreferrer"
            >
              Common Online Dispute Resolution Portal
            </a>
          </li>
        </ul>
      </article>

      <p id="copyrightssection">
        &copy; {currentYear}, Shareway Securities Private Limited. All rights
        reserved.
      </p>
    </div>
  );
};

export default Footer;
