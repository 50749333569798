import React from "react";
import "./table.css";

const Table = () => {
  return (
    <div className="escalate-matrix-container">
      <table className="escalate-matrix">
        <tr>
          <td colSpan="5" className="title">
            Escalation Matrix
          </td>
        </tr>
        <tr className="bold-row">
          <th className="heading">Details Of</th>
          <th className="heading">Name</th>
          <th className="heading">Contact No</th>
          <th className="heading">Email ID</th>
          <th className="heading" colSpan={2}>
            Address
          </th>
        </tr>
        <tr>
          <td className="content">Customer Care</td>
          <td className="content">Jeeva S</td>
          <td className="content">8217801975</td>
          <td className="content">support@fabits.com</td>
          <td className="content address-column">
            Fabits (Shareway Securities Private Ltd.) 294/1, 1st Floor, 7th
            Cross Rd, Domlur 1st Stage, Bengaluru, Karnataka - 560071
          </td>
        </tr>
        <tr>
          <th className="heading thead">Head of customer care</th>
          <td className="content">Ramesh L</td>
          <td className="content">9841455600</td>
          <td className="content">ramesh@fabits.com</td>
          <td className="content address-column">
            Fabits (Shareway Securities Private Ltd.) 294/1, 1st Floor, 7th
            Cross Rd, Domlur 1st Stage, Bengaluru, Karnataka - 560071
          </td>
        </tr>
        <tr>
          <th className="heading thead">Compliance Officer</th>
          <td className="content">Rathi R</td>
          <td className="content">9742456907</td>
          <td className="content">rathi@fabits.com</td>
          <td className="content address-column">
            Fabits (Shareway Securities Private Ltd.) 294/1, 1st Floor, 7th
            Cross Rd, Domlur 1st Stage, Bengaluru, Karnataka - 560071
          </td>
        </tr>
        <tr>
          <td className="content">CEO</td>
          <td className="content">Ajath Anjanappa</td>
          <td className="content">9986331582</td>
          <td className="content">ajath@fabits.com</td>
          <td className="content address-column">
            Fabits (Shareway Securities Private Ltd.) 294/1, 1st Floor, 7th
            Cross Rd, Domlur 1st Stage, Bengaluru, Karnataka - 560071
          </td>
        </tr>
      </table>
    </div>
  );
};

export default Table;
