import React from "react";
import styles from "./FAQ.module.css";
import Accordian from "./Acoordion/Accordion";

const faq = [
    {
        id: 1,
        question:
            "What is the difference between Gold ETFs and physical gold like jewelry or bars?",
        answer: (
            <p>
                Gold ETFs are digital investments that track the price of gold, offering
                investors the benefits of gold ownership without the hassles of storage
                and security. Unlike jewelry, Gold ETFs don’t incur making charges or
                risk of theft. Physical gold requires safe storage and is harder to sell
                at the exact market price, while Gold ETFs can be easily traded on the
                stock exchange.
            </p>
        ),
    },
    {
        id: 2,
        question:
            "How liquid are Gold ETFs, and can I access my investment anytime?",
        answer: (
            <p>
                Yes, Gold ETFs are highly liquid and can be bought or sold during
                regular trading hours on the stock exchange, just like shares. This
                flexibility allows you to access your funds whenever you need, unlike
                physical gold, which requires more effort to liquidate.
            </p>
        ),
    },
    {
        id: 3,
        question: "Why should I invest in Gold ETFs through Fabits?",
        answer: (
            <p>
                Fabits is more than just a platform; it’s your personal finance
                assistant, designed to simplify and guide your journey to financial
                independence. When you invest in Gold ETFs with Fabits, you get access
                to curated financial assistance, regular updates, and a personalized
                dashboard that helps track your investment performance. We prioritize
                security, ensuring your funds are safeguarded with the latest in
                financial technology.
            </p>
        ),
    },
    {
        id: 4,
        question: "Is investing in Gold ETFs secure with Fabits?",
        answer: (
            <p>
                : Absolutely. Fabits utilizes top-tier security protocols, protecting
                your investments and personal information. As a SEBI and NSE regulated
                investment platform, Fabits adheres to strict compliance and security
                standards, so your Gold ETF investments are as safe as they are
                accessible..
            </p>
        ),
    },
    {
        id: 5,
        question: "How does Fabits help me manage my overall finances?",
        answer: (
            <p>
                Fabits is a personal finance assistant that helps you build and manage
                your entire investment portfolio, including Gold ETFs, mutual funds,
                insurance, tax and more. With features like goal-based investing,
                curated baskets of stocks, ETFs and mutual funds, personalized financial
                insights, and automated savings plans, Fabits makes it easy for you to
                stay on track toward your financial goals. Whether it’s saving for a
                milestone or building wealth, Fabits offers the tools and support you
                need.
            </p>
        ),
    },
    {
        id: 6,
        question:
            "Are there any hidden fees when investing in Gold ETFs through Fabits?",
        answer: (
            <p>
                No hidden fees. Fabits is transparent about all charges, and Gold ETFs
                come with lower expense ratios compared to physical gold storage or
                jewelry-making charges. This means you can invest in gold at a minimal
                cost and still enjoy all the benefits without surprises.
            </p>
        ),
    },
    {
        id: 7,
        question: "Can I start with a small investment in Gold ETFs?",
        answer: (
            <p>
                Yes! One of the advantages of Gold ETFs is that you can start small.
                Fabits allows you to invest according to your budget and build your gold
                investment incrementally. This makes it easy for first-time investors to
                start investing in gold without needing a large upfront investment.
            </p>
        ),
    },
];

const FAQ = () => {
    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <h1>Frequently Asked Questions</h1>

                <div className={styles.faqContainer}>
                    {faq.map((item) => (
                        <>
                            <Accordian
                                key={item.id}
                                title={item.question}
                                desc={item.answer}
                            />
                            <hr className={styles.ruler} />
                        </>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default FAQ;
