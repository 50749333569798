import React, { useState } from "react";
import styles from "./whatisnps.module.css";
import NpsModel from "../NPSModel/NpsModel";

const WhatIsNps = () => {
  const [extraContent, setExtraContent] = useState(false);
  const [isModalOpen, setIsModelOpen] = useState(false);

  return (
    <>
      <div className={styles.container}>
        <iframe
          className={styles.iframe}
          width="1015"
          height="463"
          src="https://www.youtube.com/embed/70DiXsiG5Tw?si=0ZHsWBhdOcDUHUl0"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerpolicy="strict-origin-when-cross-origin"
          allowfullscreen
        ></iframe>

        <div className={styles.content}>
          <div>
            <h1>What is NPS?</h1>

            <div>
              <p>
                The National Pension System (NPS) is a government-initiated
                retirement savings plan in India, overseen by the Pension Fund
                Regulatory and Development Authority (PFRDA). It is designed to
                provide an income after retirement and is available to all
                Indian citizens.
              </p>
              <br />
              <p>
                Fabits is offering NPS in partnership with one of the most
                trusted banks in business - ICICI. We've partnered with ICICI in
                order to bring to you the best retirement planning scheme that
                you can explore for your NPS fund{extraContent ? "." : "..."}
              </p>
              <br />
              {extraContent ? (
                <p>
                  More about NPS: It is a cost-effective and flexible option for
                  systematic retirement savings, with contributions invested in
                  a mix of government bonds, equity, and corporate debt by
                  professional fund managers. It offers tax benefits and allows
                  partial lump sum withdrawal upon retirement, with the
                  remaining funds used to purchase an annuity for regular
                  income.
                </p>
              ) : (
                <p
                  className={styles.readMore}
                  onClick={() => {
                    setExtraContent(true);
                  }}
                >
                  read more...
                </p>
              )}
              <br />
              {extraContent && (
                <div
                  className={styles.readMore}
                  onClick={() => {
                    setExtraContent(false);
                  }}
                >
                  <span class="material-symbols-rounded">
                    keyboard_arrow_up
                  </span>
                  hide
                </div>
              )}

              <br />
              <div className={styles.openNpsBtn}>
                <button
                  onClick={() => {
                    setIsModelOpen(true);
                  }}
                >
                  <span>Open my NPS account</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <NpsModel isModalOpen={isModalOpen} setIsModelOpen={setIsModelOpen} />
    </>
  );
};

export default WhatIsNps;
