import axios from "axios";
import React, { useRef, useState } from "react";
import MyModal from "../../../Model";
import ReCAPTCHA from "react-google-recaptcha";
import styles from "./npsModel.module.css";

const NpsModel = ({ setIsModelOpen, isModalOpen }) => {
  const [errors, setErrors] = useState({});
  const [response, setResponse] = useState(null);

  const recaptcha = useRef();
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
  });

  const closeDialog = () => {
    setIsModelOpen(false);
  };

  const handleChange = (e) => {
    const { name, type, checked, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSubmit = async () => {
    try {
      const res = await axios.post(
        "https://apiuat-mywealth.fabits.com/customerservice/api/npsEnquiry",
        {
          firstName: formData.firstName,
          lastName: formData.lastName,
          phoneNumber: formData.phoneNumber,
          email: formData.email,
          source: "Website",
        }
      );

      setResponse(res.data);
      window.open(
        "https://digital-onboard.finnate.app/aWNpY2lwcnVwZW5zaW9uLzIvcGFyYW1zTWV0YT9yZWZlcnJhbENvZGU9UEZNMDAwMDI2MjA5JmFnZW50Q29kZT1O"
      );

      setFormData({
        firstName: "",
        lastName: "",
        phoneNumber: "",
        email: "",
      });

      setIsModelOpen(false);
    } catch (error) {
      console.error("Error:", error);
    }
  };
  return (
    <MyModal isOpen={isModalOpen} onRequestClose={closeDialog}>
      <h3>Hire a professional!</h3>
      <p>Let licensed CAs handle your ITR filing for FY23-24</p>

      <div className="seprator" />
      <form className="form-container">
        <div className="form-grid">
          <div className="form-group">
            <label htmlFor="firstName">First name:</label>
            <input
              type="text"
              id="firstName"
              name="firstName"
              placeholder="eg. Dhruv"
              value={formData.firstName}
              onChange={handleChange}
              required
            />
            {errors.firstName && (
              <span className="error">{errors.firstName}</span>
            )}
          </div>
          <div className="form-group">
            <label htmlFor="lastName">Last name:</label>
            <input
              type="text"
              id="lastName"
              name="lastName"
              placeholder="eg. Sharma"
              value={formData.lastName}
              onChange={handleChange}
              required
            />
            {errors.lastName && (
              <span className="error">{errors.lastName}</span>
            )}
          </div>

          <div className="form-group">
            <label htmlFor="phoneNumber">Phone number:</label>
            <input
              type="tel"
              id="phoneNumber"
              name="phoneNumber"
              placeholder="eg. 9871013579"
              value={formData.phoneNumber}
              onChange={handleChange}
              required
            />
            {errors.phoneNumber && (
              <span className="error">{errors.phoneNumber}</span>
            )}
          </div>
          <div className="form-group">
            <label htmlFor="user_email">Email:</label>
            <input
              type="email"
              id="user_email"
              name="email"
              placeholder="eg. dhruv.s@email.com"
              value={formData.email}
              onChange={handleChange}
              required
            />
            {errors.email && <span className="error">{errors.email}</span>}
          </div>
        </div>

        <div className="seprator" />
      </form>

      <div
        className="modal-btn-layout"
        style={{ display: "flex", gap: "20px", alignItems: "center" }}
      >
        <div className={styles.captcha}>
          <ReCAPTCHA
            ref={recaptcha}
            sitekey={`6LdJW-spAAAAANJ9T03r-2mv0bOy1dTL05_Wm3rh`}
          />
        </div>
        <div className="button_container" style={{ height: "40px" }}>
          <button onClick={closeDialog} className="close-button">
            Close
          </button>
          <button
            type="submit"
            onClick={handleSubmit}
            className={styles["callback-button"]}
          >
            Open My Account
          </button>
        </div>
      </div>
    </MyModal>
  );
};

export default NpsModel;
